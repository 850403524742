import {
  GETTING_GENUS_CARE_INFOS,
  GET_GENUS_CARE_INFOS_SUCCEED,
  GETTING_GENUS_CARE_INFO,
  GET_GENUS_CARE_INFO_SUCCEED,
} from '../constants/genusCareInfo.constants'

const initialState = {
  genusCareInfos: [],
  gettingGenusCareInfos: false,
  genusCareInfo: {},
  gettingGenusCareInfo: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING_GENUS_CARE_INFOS:
      return {
        ...state,
        gettingGenusCareInfos: true,
      }
    case GET_GENUS_CARE_INFOS_SUCCEED:
      return {
        ...state,
        genusCareInfos: action.payload,
        gettingGenusCareInfos: false,
      }
    case GETTING_GENUS_CARE_INFO:
      return {
        ...state,
        gettingGenusCareInfo: true,
      }
    case GET_GENUS_CARE_INFO_SUCCEED:
      return {
        ...state,
        genusCareInfo: action.payload,
        gettingGenusCareInfo: false,
      }
    default:
      return state
  }
}
