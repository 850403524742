import Parse from '../parse'
const CareIcon = Parse.Object.extend('CareIcon')

export default {
  async getCareIcons() {
    let careIconQuery = new Parse.Query(CareIcon)

    const results = await careIconQuery.find()

    return results.map(genus => genus.toJSON())
  },

  async getCareIcon(objectId) {
    let careIconQuery = new Parse.Query(CareIcon)

    const result = await careIconQuery.get(objectId)

    return result.toJSON()
  },
}
