import snappLedgerApi from '../api/modules/snappLedger.api'
import {
  GET_SNAPP_LEDGERS_SUCCEED,
  GET_SNAPP_LEDGER_SUCCEED,
  GETTING_SNAPP_LEDGERS,
  GETTING_SNAPP_LEDGER,
  UPDATING_SNAPP_LEDGER,
  UPDATE_SNAPP_LEDGER_SUCCEED,
  SUBSCRIBING_TO_SNAPP_LEDGERS,
  SUBSCRIBED_TO_SNAPP_LEDGERS,
  CREATED_SNAPP_LEDGER_LIVE_QUERY,
  UPDATED_SNAPP_LEDGER_LIVE_QUERY,
  DELETED_SNAPP_LEDGER_LIVE_QUERY,
} from '../constants/snappLedger.constants'

export default {
  getAllSnappLedgers(params) {
    return async dispatch => {
      dispatch({
        type: GETTING_SNAPP_LEDGERS,
      })
      const snappLedgers = await snappLedgerApi.getSnappLedgers(params)
      return dispatch({
        type: GET_SNAPP_LEDGERS_SUCCEED,
        payload: snappLedgers,
      })
    }
  },
  subscribeToSnappLedgers(params) {
    return async dispatch => {
      dispatch({
        type: SUBSCRIBING_TO_SNAPP_LEDGERS,
      })

      const liveQuery = await snappLedgerApi.subscribeToSnappLedgers(params)

      liveQuery.on('open', () => {
        dispatch({
          type: SUBSCRIBED_TO_SNAPP_LEDGERS,
        })
      })

      liveQuery.on('create', snappLedger => {
        dispatch({
          type: CREATED_SNAPP_LEDGER_LIVE_QUERY,
          payload: snappLedger.toJSON(),
        })
      })

      liveQuery.on('update', snappLedger => {
        dispatch({
          type: UPDATED_SNAPP_LEDGER_LIVE_QUERY,
          payload: snappLedger.toJSON(),
        })
      })

      liveQuery.on('enter', snappLedger => {
        dispatch({
          type: CREATED_SNAPP_LEDGER_LIVE_QUERY,
          payload: snappLedger.toJSON(),
        })
      })

      liveQuery.on('leave', snappLedger => {
        dispatch({
          type: DELETED_SNAPP_LEDGER_LIVE_QUERY,
          payload: snappLedger.toJSON(),
        })
      })

      liveQuery.on('delete', snappLedger => {
        dispatch({
          type: DELETED_SNAPP_LEDGER_LIVE_QUERY,
          payload: snappLedger.toJSON(),
        })
      })
    }
  },
  getSnappLedger(objectId) {
    return async dispatch => {
      dispatch({
        type: GETTING_SNAPP_LEDGER,
      })
      const snappLedger = await snappLedgerApi.getSnappLedger(objectId)
      return dispatch({
        type: GET_SNAPP_LEDGER_SUCCEED,
        payload: snappLedger,
      })
    }
  },
  updateSnappLedger(snappLedgerData) {
    return async dispatch => {
      dispatch({
        type: UPDATING_SNAPP_LEDGER,
      })
      const snappLedger = snappLedgerApi.updateSnappLedger(snappLedgerData)
      return dispatch({
        type: UPDATE_SNAPP_LEDGER_SUCCEED,
        payload: snappLedger,
      })
    }
  },
}
