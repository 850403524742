import {
  GETTING_SNAPP_LEDGERS,
  GET_SNAPP_LEDGERS_SUCCEED,
  GETTING_SNAPP_LEDGER,
  GET_SNAPP_LEDGER_SUCCEED,
  UPDATING_SNAPP_LEDGER,
  UPDATE_SNAPP_LEDGER_SUCCEED,
  SUBSCRIBING_TO_SNAPP_LEDGERS,
  SUBSCRIBED_TO_SNAPP_LEDGERS,
  CREATED_SNAPP_LEDGER_LIVE_QUERY,
  DELETED_SNAPP_LEDGER_LIVE_QUERY,
  UPDATED_SNAPP_LEDGER_LIVE_QUERY,
} from '../constants/snappLedger.constants'

const initialState = {
  snappLedgers: [],
  gettingSnappLedgers: false,
  subscribedToSnappLedgers: false,
  snappLedger: {},
  gettingSnappLedger: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING_SNAPP_LEDGERS:
      return {
        ...state,
        snappLedgers: [],
        gettingSnappLedgers: true,
      }
    case GET_SNAPP_LEDGERS_SUCCEED:
      return {
        ...state,
        snappLedgers: action.payload,
        gettingSnappLedgers: false,
      }
    case SUBSCRIBING_TO_SNAPP_LEDGERS:
      return {
        ...state,
        subscribedToSnappLedgers: false,
      }
    case SUBSCRIBED_TO_SNAPP_LEDGERS:
      return {
        ...state,
        subscribedToSnappLedgers: true,
      }
    case CREATED_SNAPP_LEDGER_LIVE_QUERY: {
      console.log(action.payload)
      // TODO: just pushing isn't good enough - we're assuming the dates are chronological
      state.snappLedgers.push(action.payload)
      return {
        ...state,
        // we have to create a copy to trigger ui updates
        snappLedgers: state.snappLedgers.slice(),
      }
    }
    case UPDATED_SNAPP_LEDGER_LIVE_QUERY: {
      console.log(action.payload)
      const i = state.snappLedgers.findIndex(
        (sC) => sC.objectId === action.payload.objectId
      )
      if (i > -1) {
        state.snappLedgers[i] = action.payload
      }
      return {
        ...state,
        // we have to create a copy to trigger ui updates
        snappLedgers: state.snappLedgers.slice(),
      }
    }
    case DELETED_SNAPP_LEDGER_LIVE_QUERY: {
      console.log(action.payload)
      const i = state.snappLedgers.findIndex(
        (sC) => sC.objectId === action.payload.objectId
      )
      if (i > -1) {
        state.snappLedgers.splice(i, 1)
      }
      return {
        ...state,
        // we have to create a copy to trigger ui updates
        snappLedgers: state.snappLedgers.slice(),
      }
    }
    case GETTING_SNAPP_LEDGER:
      return {
        ...state,
        gettingSnappLedger: true,
      }
    case GET_SNAPP_LEDGER_SUCCEED:
      return {
        ...state,
        snappLedger: action.payload,
        gettingSnappLedger: false,
      }
    case UPDATING_SNAPP_LEDGER:
      return {
        ...state,
        updatingSnappLedger: true,
      }
    case UPDATE_SNAPP_LEDGER_SUCCEED:
      return {
        ...state,
        snappLedger: action.payload,
        updatingSnappLedger: false,
      }
    default:
      return state
  }
}
