import {
  GETTING_CARE_ICONS,
  GET_CARE_ICONS_SUCCEED,
  GETTING_CARE_ICON,
  GET_CARE_ICON_SUCCEED,
} from '../constants/careIcon.constants'
import careIconApi from '../api/modules/careIcon.api'

export default {
  getMonths(params) {
    return async dispatch => {
      dispatch({
        type: GETTING_CARE_ICONS,
      })
      const careIcons = await careIconApi.getCareIcons(params)
      return dispatch({
        type: GET_CARE_ICONS_SUCCEED,
        payload: careIcons,
      })
    }
  },
  getMonth(objectId) {
    return async dispatch => {
      dispatch({ type: GETTING_CARE_ICON })
      const careIcon = await careIconApi.getCareIcon(objectId)
      dispatch({
        type: GET_CARE_ICON_SUCCEED,
        payload: careIcon,
      })
      return careIcon
    }
  },
}
