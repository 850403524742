import {
  GET_SNAPPS_SUCCEED,
  GETTING_SNAPPS,
  GET_SNAPPS_ERROR,
  GETTING_SNAPP,
  GET_SNAPP_SUCCEED,
  GET_SNAPP_ERROR,
  LOCKING_SNAPP,
  LOCK_LIST_SNAPP,
  ERROR_LOCKING_SNAPP,
  UNLOCK_LIST_SNAPP,
  SET_SNAPPS_REQUEST_PARAMS,
  UPDATING_SNAPP,
  UPDATED_SNAPP,
  ERROR_UPDATING_SNAPP,
  ASSIGNING_PLANT_TO_SNAPP,
  ASSIGNED_PLANT_TO_SNAPP,
  ERROR_ASSIGNING_PLANT_TO_SNAPP,
  ADD_SNAPP_ALERT,
  REMOVE_SNAPP_ALERT,
  LOCKED_SNAPP,
} from '../constants/snapp.constants'
import { snappOrderBys, snappTypes } from '../constants/index'

const initialState = {
  gettingSnapps: false,
  snapps: [],
  snappsRequestParams: {
    userCommentStatus: true,
    orderBy: snappOrderBys[0],
    snappType: snappTypes[0],
    regions: [],
    genera: [],
    lockedOnly: false,
    expert: null,
  },
  lockingSnapp: false,
  lockedSnapps: [],
  gettingSnapp: false,
  snapp: {
    sendForIdentification: null,
    snappImageURI: '',
    snappThumbURI: '',
    latitude: 0,
    longitude: 0,
    region: null,
    snappUser: 'Unknown user',
    extraPhotos: [],
  },
  updatingSnapp: false,
  assigningPlantToSnapp: false,
  error: false,
  errorMessage: '',
  alerts: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SNAPP_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      }
    case REMOVE_SNAPP_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts.slice(0, action.payload),
          ...state.alerts.slice(action.payload + 1),
        ],
      }
    case GETTING_SNAPPS:
      return {
        ...state,
        gettingSnapps: true,
        error: false,
      }
    case GET_SNAPPS_SUCCEED:
      return {
        ...state,
        snapps: action.payload,
        gettingSnapps: false,
        error: false,
      }
    case GET_SNAPPS_ERROR:
      return {
        ...state,
        snapps: [],
        gettingSnapps: false,
        error: true,
        errorMessage: action.payload,
      }
    case SET_SNAPPS_REQUEST_PARAMS:
      return {
        ...state,
        snappsRequestParams: {
          ...state.snappsRequestParams,
          ...action.payload,
        },
      }
    case GETTING_SNAPP:
      return {
        ...state,
        gettingSnapp: true,
        snapp: initialState.snapp,
        error: false,
      }
    case GET_SNAPP_SUCCEED:
      return {
        ...state,
        gettingSnapp: false,
        snapp: action.payload,
        error: false,
      }
    case GET_SNAPP_ERROR:
      return {
        ...state,
        gettingSnapp: false,
        error: true,
        errorMessage: action.payload,
      }
    case LOCKING_SNAPP:
      return {
        ...state,
        lockingSnapp: true,
        error: false,
      }
    case LOCKED_SNAPP:
      return {
        ...state,
        snapp: action.payload,
        lockingSnapp: false,
        error: false,
      }
    case ERROR_LOCKING_SNAPP:
      return {
        ...state,
        lockingSnapp: false,
        error: true,
        errorMessage: action.payload,
      }
    case LOCK_LIST_SNAPP: {
      const i = state.snapps.findIndex(
        (s) => s.objectId === action.payload.objectId
      )
      if (i > -1) {
        state.snapps[i] = action.payload
      }
      return state
    }
    case UNLOCK_LIST_SNAPP: {
      const i = state.lockedSnapps.indexOf(action.payload)
      if (i > -1) {
        state.lockedSnapps.splice(i, 1)
      }
      return state
    }
    case UPDATING_SNAPP:
      return {
        ...state,
        error: false,
        updatingSnapp: true,
      }
    case UPDATED_SNAPP:
      return {
        ...state,
        error: false,
        snapp: action.payload,
        updatingSnapp: false,
      }
    case ERROR_UPDATING_SNAPP:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
      }
    case ASSIGNING_PLANT_TO_SNAPP:
      return {
        error: false,
        assigningPlantToSnapp: true,
      }
    case ASSIGNED_PLANT_TO_SNAPP:
      return {
        error: false,
        snapp: action.payload,
        assigningPlantToSnapp: false,
      }
    case ERROR_ASSIGNING_PLANT_TO_SNAPP:
      return {
        error: true,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}
