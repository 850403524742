import Parse from '../parse'
const SnappLedger = Parse.Object.extend('SnappLedger')

const prepareParamsForSnappLedgers = params => {
  let snappLedgerQuery = new Parse.Query(SnappLedger)
  if (params.orderBy) {
    snappLedgerQuery[params.orderBy.value[0]](params.orderBy.value[1])
  } else {
    snappLedgerQuery.descending('updatedAt')
  }

  if (params.snapp) {
    snappLedgerQuery.equalTo('snapp', {
      __type: 'Pointer',
      className: 'Snapp',
      objectId: params.snapp,
    })
  }

  if (params.action) {
    snappLedgerQuery.equalTo('action', params.action)
  }

  if (params.user) {
    snappLedgerQuery.equalTo('user', {
      __type: 'Pointer',
      className: '_User',
      objectId: params.user,
    })
  }

  return snappLedgerQuery
}

export default {
  async getSnappLedgers(params) {
    const snappLedgerQuery = prepareParamsForSnappLedgers(params)

    const results = await snappLedgerQuery.find()

    return results.map(snappLedger => snappLedger.toJSON())
  },

  async subscribeToSnappLedgers(params) {
    const snappLedgerQuery = prepareParamsForSnappLedgers(params)
    return snappLedgerQuery.subscribe()
  },

  async getSnappLedger(objectId) {
    let snappLedgerQuery = new Parse.Query(SnappLedger)

    const result = await snappLedgerQuery.get(objectId)

    return result.toJSON()
  },

  async updateSnappLedger(snappLedger) {
    delete snappLedger.ACL
    const snappLedgerQuery = new Parse.Query(SnappLedger)
    const snappLedgerUpdate = await snappLedgerQuery.get(snappLedger.objectId)
    await snappLedgerUpdate.save(snappLedger)
    return snappLedgerUpdate.toJSON()
  },
}
