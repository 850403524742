import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import {
  reduxForm,
  formValueSelector,
  propTypes as reduxFormPropTypes,
  SubmissionError,
  Field,
} from 'redux-form'
import {
  Button,
  Col,
  Jumbotron,
  Row,
  Tooltip,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Toast,
  ToastHeader,
  ToastBody,
} from 'reactstrap'
import moment from 'moment'

import Layout from '../containers/Layout'
import ExpertChat from './ExpertChat'
import IdentifyPlant from './IdentifyPlant'
import Plant from './Plant'
import ExtraPhotosCarousel from './ExtraPhotosCarousel'
import SnappLocation from './SnappLocation'
import careInfoActions from '../actions/careInfo.actions'
import snappActions from '../actions/snapp.actions'
import plantActions from '../actions/plant.actions'
import snappCommentActions from '../actions/snappComment.actions'
import snappLedgerActions from '../actions/snappLedger.actions'
import userActions from '../actions/user.actions'
import userApi from '../api/modules/user.api'
import CustomAsyncSelect from './form/CustomAsyncSelect'
import Loading from './Loading'
import Helmet from 'react-helmet'

const form = 'case'
const selector = formValueSelector(form)

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.updateSnapp(values)
  } catch (err) {
    throw new SubmissionError({
      _error: 'Error updating snapp',
    })
  }
}

export class Case extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    self: PropTypes.object,
    gettingSnapp: PropTypes.bool.isRequired,
    getSnapp: PropTypes.func.isRequired,
    getSnapps: PropTypes.func.isRequired,
    lockSnapp: PropTypes.func.isRequired,
    getPlant: PropTypes.func.isRequired,
    getAllSnappComments: PropTypes.func.isRequired,
    subscribeToSnappComments: PropTypes.func.isRequired,
    getAllSnappLedgers: PropTypes.func.isRequired,
    subscribeToSnappLedgers: PropTypes.func.isRequired,
    issueOneCreditForUser: PropTypes.func.isRequired,
    removeSnappAlert: PropTypes.func.isRequired,
    extraPhotos: PropTypes.array,
    match: PropTypes.object.isRequired,
    snapp: PropTypes.object,
    snapps: PropTypes.array,
    history: PropTypes.object.isRequired,
    snappAlerts: PropTypes.array,
    snappError: PropTypes.bool.isRequired,
    snappErrorMessage: PropTypes.string,
    snappsRequestParams: PropTypes.object,
    pageTitle: PropTypes.string.isRequired,
  }

  state = {
    dropdownOpen: false,
    dateTooltipOpen: false,
    sendToOtherExpertModalOpen: false,
  }

  getArrayIndexOfSnapp(snappObjectId = null) {
    return this.props.snapps.findIndex(
      (snapp) => snapp.objectId === snappObjectId
    )
  }

  // HANDLERS

  async handleChangeCase(direction) {
    const snappsInCurrentFilter = this.props.snapps
    let currentCaseIndex = this.getArrayIndexOfSnapp(this.props.match.params.id)

    if (direction === 'previous' && currentCaseIndex > 0) currentCaseIndex--
    if (
      direction === 'next' &&
      currentCaseIndex < snappsInCurrentFilter.length - 1
    )
      currentCaseIndex++

    const newCaseId = snappsInCurrentFilter[currentCaseIndex].objectId
    this.props.history.push(`/case/${newCaseId}`)

    await this._handleLoadCase(newCaseId)
  }

  handleRevisitIdentification = () => {
    this.props.change('expertIdentifiedPlant', null)
    this.props.change('isSnappIdentified', false)
  }

  handleSendToOtherExpert = () => {}

  handleConsultFacebookGroup = () => {}

  handleIssueOneCreditToUser = async () => {
    await this.props.issueOneCreditForUser(this.props.snapp.snappUser.objectId)
    this.props.addSnappAlert({
      header: 'Added credit',
      body: 'Added one credit for user',
    })
  }

  handleCloseSession = () => {
    this.props.change('userCommentStatus', false)
  }

  async _handleLoadCase(caseToLoad = this.props.match.params.id) {
    if (caseToLoad) {
      const snapp = await this.props.getSnapp(caseToLoad)
      if (snapp) {
        await this.props.lockSnapp(snapp, this.props.self)
        if (snapp.expertIdentifiedPlant) {
          await this.props.getPlant(snapp.expertIdentifiedPlant.objectId)
        }
        const params = { snapp: snapp.objectId }
        await this.props.getAllSnappComments(params)
        await this.props.subscribeToSnappComments(params)
        await this.props.getAllSnappLedgers({ snapp: caseToLoad })
        await this.props.subscribeToSnappLedgers({ snapp: caseToLoad })
      }
    }
  }

  async _handleLoadCases() {
    await this.props.getSnapps(this.props.snappsRequestParams);
  }

  // LIFECYCLE

  async componentDidMount() {
    await this._handleLoadCase()
    await this._handleLoadCases()
  }

  render() {
    const {
      handleSubmit,
      pristine,
      gettingSnapp,
      snapp,
      snapps,
      isSnappIdentifiedValue,
      expertIdentifiedPlantValue,
      userCommentStatusValue,
      issuingOneCreditForUser,
      reset,
      snappAlerts,
      removeSnappAlert,
      snappError,
      snappErrorMessage,
      pageTitle,
    } = this.props
    const snappUser = snapp.snappUser

    const currentCaseIndex = this.getArrayIndexOfSnapp(
      this.props.match.params.id
    )

    const previousButtonState = currentCaseIndex === 0 ? true : false
    const nextButtonState =
      currentCaseIndex === snapps.length - 1 ? true : false

    return (
      <Layout>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {gettingSnapp && <Loading />}
        <div className="p-3 p-md-5">
          <Row className="mb-3 pb-3 border-bottom">
            <Col md="9">
              <Button color="link" onClick={() => this.props.history.goBack()}>
                &lt; Back
              </Button>
            </Col>

            <Col md="3">
              <Button
                color="link"
                onClick={() => this.handleChangeCase('previous')}
                disabled={previousButtonState}
              >
                &lt; Previous case
              </Button>{' '}
              |{' '}
              <Button
                color="link"
                onClick={() => this.handleChangeCase('next')}
                disabled={nextButtonState}
              >
                Next case &gt;
              </Button>
            </Col>
          </Row>

          <Row>
            {expertIdentifiedPlantValue && (
              <Col md="4">
                <h3>{expertIdentifiedPlantValue.latinName}</h3>
                <p>{expertIdentifiedPlantValue.commonName}</p>
                <h4>
                  <Badge color="primary">plant: users col.</Badge>

                  <Badge color="secondary" className="ml-1">
                    new
                  </Badge>
                </h4>
              </Col>
            )}

            <Col>
              <Row>
                <Col md="6">
                  {snappUser && (
                    <>
                      <p>
                        {snappUser.firstName} {snappUser.lastName}
                      </p>
                      <p>{snappUser.region && snappUser.region.regionName}</p>
                    </>
                  )}
                </Col>
                <Col md="6">
                  <p>Locked for you only while you edit</p>
                  <p>&pound;0 locked in this identification for you</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <span id="dateAsked">
                      Identification asked {snapp.identificationRequestedDate}
                    </span>
                    <Tooltip
                      placement="bottom"
                      isOpen={this.state.dateTooltipOpen}
                      target="dateAsked"
                      toggle={() =>
                        this.setState({
                          dateTooltipOpen: !this.state.dateTooltipOpen,
                        })
                      }
                    >
                      {snapp.identificationRequestedDateToolTip}
                    </Tooltip>
                    <span className="d-md-none">
                      {' ' + snapp.identificationRequestedDateToolTip}
                    </span>
                    {expertIdentifiedPlantValue && (
                      <Button
                        color="link"
                        onClick={() => this.handleRevisitIdentification()}
                      >
                        <i className="fas fa-pencil-alt"></i> Revisit
                        identification
                      </Button>
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs="12" sm="4" className="p-3">
              <ExtraPhotosCarousel snapp={snapp} />
            </Col>

            <Col xs="12" sm="8" className="p-3">
              {!expertIdentifiedPlantValue && (
                <Row>
                  <Col>
                    <IdentifyPlant />
                  </Col>
                </Row>
              )}

              <Row className="mt-3">
                <Col>
                  <h3>Messages</h3>

                  <ExpertChat />
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Button
                    color="link"
                    onClick={() => {
                      reset()
                      this.setState({
                        sendToOtherExpertModalOpen: true,
                      })
                    }}
                  >
                    <i className="fas fa-share"></i> Send to other expert
                  </Button>
                  <Button
                    color="link"
                    onClick={() => this.handleConsultFacebookGroup()}
                  >
                    <i className="fab fa-facebook-f"></i> Consult the facebook
                    group
                  </Button>
                  <Button
                    color="link"
                    onClick={() => this.handleIssueOneCreditToUser()}
                  >
                    {issuingOneCreditForUser ? (
                      <i className="fas fa-asterisk fa-spin"></i>
                    ) : (
                      <i className="fas fa-star"></i>
                    )}{' '}
                    Issue 1 credit to the user
                  </Button>
                </Col>
                <Col className="text-right">
                  <Button
                    color="secondary"
                    onClick={() => this.handleCloseSession()}
                    disabled={!userCommentStatusValue}
                  >
                    Close the session
                  </Button>
                  {userCommentStatusValue && (
                    <p className="pt-2">
                      Make sure the user is happy and close this session{' '}
                      {/* @TODO: add in amount earnt */}
                    </p>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            {isSnappIdentifiedValue && expertIdentifiedPlantValue ? (
              <Col xs="12" md="6" lg="8">
                <Plant />
              </Col>
            ) : null}

            <Col xs="12" md="6" lg="4">
              <h3>Location</h3>

              {snapp.latitude !== undefined && snapp.longitude !== undefined ? (
                <SnappLocation lat={snapp.latitude} lng={snapp.longitude} />
              ) : (
                <Jumbotron>
                  <h2>No location data found</h2>
                </Jumbotron>
              )}
            </Col>
          </Row>
        </div>
        <form onSubmit={handleSubmit}>
          <Modal
            isOpen={this.state.sendToOtherExpertModalOpen}
            toggle={() =>
              this.setState({
                sendToOtherExpertModalOpen: !this.state
                  .sendToOtherExpertModalOpen,
              })
            }
          >
            <ModalHeader>Send to other expert</ModalHeader>
            <ModalBody>
              <Field
                name="expert"
                component={CustomAsyncSelect}
                loadOptions={userApi.search}
                placeholder="Enter an expert's email address…"
                format={(value) =>
                  value && {
                    value: value.objectId,
                    label: value.username,
                  }
                }
                parse={(value) => ({
                  __type: 'Pointer',
                  className: '_User',
                  objectId: value.value,
                  username: value.label,
                })}
              />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary" disabled={pristine}>
                Assign to other expert
              </Button>{' '}
              <Button
                color="secondary"
                onClick={() => {
                  reset()
                  this.setState({
                    sendToOtherExpertModalOpen: false,
                  })
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Toast isOpen={snappError} className="fixed-bottom mb-3 ml-3">
            <ToastHeader>Error</ToastHeader>
            <ToastBody>{snappErrorMessage.message}</ToastBody>
          </Toast>
          {snappAlerts &&
            snappAlerts.map((snappAlert, i) => (
              <Toast key={i} isOpen={true} className="fixed-bottom mb-3 ml-3">
                <ToastHeader toggle={() => removeSnappAlert(i)}>
                  {snappAlert.header}
                </ToastHeader>
                <ToastBody>{snappAlert.body}</ToastBody>
              </Toast>
            ))}
        </form>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  self: state.user.self,
  snapps: state.snapp.snapps,
  snappsRequestParams: state.snapp.snappsRequestParams,
  snapp: {
    ...state.snapp.snapp,
    identificationRequestedDate:
      state.snapp.snapp.sendForIdentification &&
      moment(state.snapp.snapp.sendForIdentification.iso).fromNow(),
    identificationRequestedDateToolTip:
      state.snapp.snapp.sendForIdentification &&
      moment(state.snapp.snapp.sendForIdentification.iso).format(
        'dddd, MMMM Do YYYY, h:mm:ss a'
      ),
  },
  gettingSnapp: state.snapp.gettingSnapp,
  expertIdentifiedPlantValue: selector(state, 'expertIdentifiedPlant'),
  isSnappIdentifiedValue: selector(state, 'isSnappIdentified'),
  userCommentStatusValue: selector(state, 'userCommentStatus'),
  ...state.snappUser,
  ...state.plants,
  issuingOneCreditForUser: state.user.issuingOneCreditForUser,
  snappAlerts: state.snapp.alerts,
  snappError: state.snapp.error,
  snappErrorMessage: state.snapp.errorMessage,
  pageTitle: selector(state, 'isSnappIdentified')
    ? `✔ Identified ${state.snapp.snapp.snappType}`
    : `${state.snapp.snapp.snappType} Identification`,
  initialValues: state.snapp.snapp,
})

const mapDispatchToProps = {
  ...careInfoActions,
  ...snappActions,
  ...plantActions,
  ...snappCommentActions,
  ...snappLedgerActions,
  ...userActions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Case)
)
