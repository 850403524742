import React, { useEffect, useRef } from 'react'
import { Input, FormFeedback } from 'reactstrap'

const ExpertChatInput = ({ meta: { valid, error }, input, ...props }: any) => {
  return (
    <>
      <Input
        onChange={input.onChange}
        value={input.value}
        autoFocus
        {...props}
      />
      <FormFeedback valid={valid} tooltip>
        {error}
      </FormFeedback>
    </>
  )
}

export default ExpertChatInput
