import Parse from '../parse'
import { client } from '../algolia'

const plantIndex = client.initIndex('plant')
const Plant = Parse.Object.extend('Plant')

export default {
  async getAllPlants(params) {
    let plantQuery = new Parse.Query(Plant)

    if (params.orderBy) {
      plantQuery[params.orderBy[0]](params.orderBy[1])
    } else {
      plantQuery.ascending('latinName')
    }

    const results = await plantQuery.find()

    return results
  },

  async getPlant(objectId) {
    let plantQuery = new Parse.Query(Plant)
    plantQuery.include('genus')
    const plant = await plantQuery.get(objectId)
    return plant.toJSON()
  },

  async updatePlant(plantData) {
    delete plantData.ACL
    const plantQuery = new Parse.Query(Plant)
    const plantUpdate = await plantQuery.get(plantData.objectId)
    await plantUpdate.save(plantData)
    return plantUpdate.toJSON()
  },

  async search(searchText) {
    searchText = searchText || ''
    const results = await plantIndex.search({
      query: searchText,
      hitsPerPage: 10,
      page: 0,
    })
    return results.hits.map(hit => {
      let label = hit.latinName ? hit.latinName : ''
      label += hit.latinName && hit.commonName ? ' ' : ''
      label += hit.commonName ? hit.commonName : ''
      return {
        value: hit.objectID,
        label,
        plantDescription: hit.plantDescription || '',
      }
    })
  },

  async createPlant(values) {
    const plant = new Plant()
    await plant.save(values)
  },
}
