import Login from '../components/Login'
import Case from '../components/Case'
import List from '../components/List'
import CareInfo from '../components/CareInfo'
import CareInfoGrid from '../components/CareInfoGrid';

const indexRoutes = [
  { path: '/login', component: Login },
  { path: '/care-info/:id', component: CareInfo, needsAuth: true },
  { path: '/care-info', component: CareInfoGrid, needsAuth: true },
  { path: '/case/:id', component: Case, needsAuth: false },
  { path: '/', component: List, needsAuth: true },
]

export default indexRoutes
