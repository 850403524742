import React from 'react';
import { connect } from 'react-redux'
import CareInfoForm from './form/CareInfoForm'

interface CareInfoProps {

}

export const CareInfo = (props: any) => {
  return (
    <CareInfoForm {...props} />
  )
}

export default connect(null)(CareInfo)

