import Parse from '../parse'

const SnappComments = Parse.Object.extend('SnappComments')

const prepareParamsForSnappComments = params => {
  let snappCommentQuery = new Parse.Query(SnappComments)

  snappCommentQuery.include(['user.publicName', 'expert'])

  if (params.orderBy) {
    snappCommentQuery[params.orderBy[0]](params.orderBy[1])
  } else {
    snappCommentQuery.ascending('createdAt')
  }

  if (params.snapp) {
    snappCommentQuery.equalTo('snapp', {
      __type: 'Pointer',
      className: 'Snapp',
      objectId: params.snapp,
    })
  }

  return snappCommentQuery
}

export default {
  async getAllSnappComments(params) {
    const snappCommentQuery = prepareParamsForSnappComments(params)
    const results = await snappCommentQuery.find()

    return results.map(result => result.toJSON())
  },

  async subscribeToSnappComments(params) {
    const query = prepareParamsForSnappComments(params)
    return query.subscribe()
  },

  async getSnappComment(objectId) {
    let snappCommentQuery = new Parse.Query(SnappComments)
    const snappComment = await snappCommentQuery.get(objectId)
    return snappComment.toJSON()
  },

  async updateSnappComment(snappCommentData) {
    delete snappCommentData.ACL
    const snappCommentQuery = new Parse.Query(SnappComments)
    const snappCommentUpdate = await snappCommentQuery.get(
      snappCommentData.objectId
    )
    await snappCommentUpdate.save(snappCommentData)
    return snappCommentUpdate.toJSON()
  },

  async createSnappComment(snappComment) {
    let newSnappComment = new SnappComments()
    await newSnappComment.save(snappComment)
  }
}
