import Parse from '../parse'
const Snapp = Parse.Object.extend('Snapp')
const Plant = Parse.Object.extend('Plant')

export default {
  async getSnapps(params) {
    let snappQuery = new Parse.Query(Snapp)
    if (params.orderBy) {
      snappQuery[params.orderBy.value[0]](params.orderBy.value[1])
    } else {
      snappQuery.descending('updatedAt')
    }

    if (params.userCommentStatus !== undefined) {
      snappQuery.equalTo('userCommentStatus', params.userCommentStatus)
    }

    if (params.lockedOnly) {
      snappQuery
        .greaterThan('beingIdentifiedUntilTime', new Date())
        .equalTo('beingIdentifiedByExpert', Parse.User.current().toPointer())
    }

    if (params.regions && params.regions.length > 0) {
      snappQuery.containedIn(
        'region',
        params.regions.map(region => region.value)
      )
    }

    if (params.genera && params.genera.length > 0) {
      const plantQuery = new Parse.Query(Plant)
      plantQuery.containedIn(
        'genus',
        params.genera.map(genus => ({
          __type: 'Pointer',
          className: 'Genus',
          objectId: genus.value,
        }))
      )
      snappQuery.matchesQuery('expertIdentifiedPlant', plantQuery)
    }

    if (params.snappType) {
      snappQuery.equalTo('snappType', params.snappType.value)
    }

    if (params.expert) {
      snappQuery.equalTo('expert', {
        __type: 'Pointer',
        className: '_User',
        objectId: params.expert,
      })
    }

    const results = await snappQuery
      .limit(20)
      .include('region')
      .include(['snappUser.publicName'])
      .find()

    return results.map(snapp => snapp.toJSON())
  },

  async getSnapp(objectId) {
    let snappQuery = new Parse.Query(Snapp)

    const result = await snappQuery
      .include([
        'snappUser',
        'expert',
        'assignedExpert',
        'expertIdentifiedPlant',
        'region',
        'extraPhotos',
      ])
      .get(objectId)

    return result.toJSON()
  },

  async updateSnapp(snapp) {
    delete snapp.ACL
    const snappQuery = new Parse.Query(Snapp)
    const snappUpdate = await snappQuery.get(snapp.objectId)
    await snappUpdate.save(snapp)
    return snappUpdate.toJSON()
  },

  async assigningPlantToSnapp(values) {
    const snapp = Parse.Cloud.run('assignPlantToSnapp', values)
    return snapp
  },
}
