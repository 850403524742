import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Card, CardBody } from 'reactstrap'

import { connect } from 'react-redux'
import {
  reduxForm,
  Field,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form'

import CustomTextInput from './form/CustomTextInput'
import CustomAsyncSelect from './form/CustomAsyncSelect'

import plantActions from '../actions/plant.actions'
import genusActions from '../actions/genus.actions'
import careInfoActions from '../actions/genusCareInfo.actions'
import monthActions from '../actions/month.actions'
import careIconActions from '../actions/careIcon.actions'
import regionApi from '../api/modules/region.api'

const form = 'plant'

const validate = values => {
  let errors = {}

  if (!values.latinName && !values.commonName) {
    errors.latinName = 'Requires at least one name'
  }

  if (!values.genus) {
    errors.genus = 'Required'
  }

  return errors
}

const onSubmit = async (values, dispatch, props) => {
  if (values.genus) {
    const objectId = values.genus.objectId
    values.genus = {
      __type: 'Pointer',
      className: 'Genus',
      objectId,
    }
  }
  try {
    await props.updatePlant(values)
    await props.getPlant(values.objectId)
  } catch (err) {
    throw new SubmissionError({
      _error: err.message,
    })
  }
}

class Plant extends Component {
  static propTypes = {
    plant: PropTypes.object.isRequired,
    loadGenusOptions: PropTypes.func.isRequired,
    gettingGenusOptions: PropTypes.bool.isRequired,
    ...reduxFormPropTypes,
  }

  static defaultProps = {
    gettingGenusOptions: false,
  }

  async componentDidMount() {
    /* if (this.props.plant.genus) {
      await this.getCareInfoForGenus(
        this.props.plant.genus.objectId,
        this.props.plant.genus.categoryType
          ? this.props.plant.genus.categoryType.objectId
          : null
      )
      await this.props.getMonths()
      await this.props.getRegions()
      await this.props.getCareIcons()
    } */
  }

  getCareInfoForGenus = async (genusId, categoryId) => {
    await this.props.getPlantCareInfo(genusId, categoryId)
  }

  render() {
    const {
      handleSubmit,
      loadGenusOptions,
      gettingGenusOptions,
      // gettingCareInfos,
      pristine,
      invalid,
      submitFailed,
      submitting,
      error,
    } = this.props
    return (
      <Card>
        <form onSubmit={handleSubmit}>
          <CardBody>
            <Field name="latinName" label="Name" component={CustomTextInput} />
            <Field
              name="commonName"
              label="Common name"
              component={CustomTextInput}
            />
            <Field
              name="plantDescription"
              label="Description"
              type="textarea"
              component={CustomTextInput}
            />
            <Field
              name="genus"
              label="Genus"
              /* onChange={async value => {
                await this.getCareInfoForGenus(value.value)
                return value
              }} */
              format={value =>
                value && {
                  value: value.objectId,
                  label: value.genusName,
                }
              }
              normalize={(value, previousValue) => ({
                ...previousValue,
                objectId: value.value,
                genusName: value.label,
              })}
              component={CustomAsyncSelect}
              loadOptions={loadGenusOptions}
              isLoading={gettingGenusOptions}
            />

            {/* @TODO: EDITING OF CARE INFO - TO REFACTOR */}
            {/* <Button
              color="primary"
              id="genusCareInfos"
              style={{ marginBottom: '1rem' }}
              disabled={gettingCareInfos}
            >
              {gettingCareInfos ? (
                <i className="fas fa-asterisk fa-spin"></i>
              ) : (
                'Show Care Info'
              )}
            </Button>
            <UncontrolledCollapse toggler="#genusCareInfos">
              <FieldArray
                name="genusCareInfos"
                component={({ fields }) => (
                  <div>
                    {fields.map((member, index, fields) => {
                      return (
                        <div key={`${member}-care`}>
                          <Field
                            key={`${member}-careField1-${index}`}
                            name={`${member}.careField1`}
                            label="Care 1"
                            component={CustomTextInput}
                          />
                          <Field
                            key={`${member}-careField2-${index}`}
                            label="Care 2"
                            name={`${member}.careField2`}
                            component={CustomTextInput}
                          />
                          <Field
                            key={`${member}-careField3-${index}`}
                            label="Care 3"
                            name={`${member}.careField3`}
                            component={CustomTextInput}
                          />
                          <Field
                            label="Months"
                            format={values =>
                              values.map(value => ({
                                value: value.objectId,
                                label: value.seasonName,
                              }))
                            }
                            normalize={values => {
                              return this.props.months.filter(
                                month =>
                                  values.findIndex(
                                    val => val.value === month.objectId
                                  ) > -1
                              )
                            }}
                            name={`${member}.monthId`}
                            component={CustomSelect}
                            options={this.props.months.map(month => ({
                              value: month.objectId,
                              label: month.regionName,
                            }))}
                            isMulti
                          />
                          <Field
                            label="Regions"
                            format={values =>
                              values.map(value => ({
                                value: value.objectId,
                                label: value.regionName,
                              }))
                            }
                            normalize={values => {
                              return this.props.months.filter(
                                month =>
                                  values.findIndex(
                                    val => val.value === month.objectId
                                  ) > -1
                              )
                            }}
                            name={`${member}.regions`}
                            component={CustomSelect}
                            options={this.props.regions.map(month => ({
                              value: month.objectId,
                              label: month.seasonName,
                            }))}
                            isMulti
                          />
                          <hr />
                        </div>
                      )
                    })}
                  </div>
                )}
              />

              <FieldArray
                name="categoryCareInfos"
                component={({ fields }) => (
                  <div>
                    {fields.map((member, index, fields) => {
                      return (
                        <div key={`${member}-care`}>
                          <Field
                            key={`${member}-careField1-${index}`}
                            name={`${member}.careField1`}
                            label="Care 1"
                            component={CustomTextInput}
                          />
                          <Field
                            key={`${member}-careField2-${index}`}
                            label="Care 2"
                            name={`${member}.careField2`}
                            component={CustomTextInput}
                          />
                          <Field
                            key={`${member}-careField3-${index}`}
                            label="Care 3"
                            name={`${member}.careField3`}
                            component={CustomTextInput}
                          />
                          <Field
                            label="Months"
                            format={values =>
                              values.map(value => ({
                                value: value.objectId,
                                label: value.seasonName,
                              }))
                            }
                            normalize={values => {
                              return this.props.months.filter(
                                month =>
                                  values.findIndex(
                                    val => val.value === month.objectId
                                  ) > -1
                              )
                            }}
                            name={`${member}.monthId`}
                            component={CustomSelect}
                            options={this.props.months.map(month => ({
                              value: month.objectId,
                              label: month.seasonName,
                            }))}
                            isMulti
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
              />
            </UncontrolledCollapse> */}
            <Button
              type="submit"
              color="primary"
              disabled={pristine || invalid || submitting}
            >
              {submitting ? (
                <i className="fas fa-asterisk fa-spin"></i>
              ) : (
                'UPDATE PLANT'
              )}
            </Button>
          </CardBody>
        </form>
        <Alert
          color="danger"
          isOpen={submitFailed}
          toggle={() => this.props.reset()}
          className="fixed-bottom m-5"
        >
          {error || 'Login failed, please try again'}
        </Alert>
      </Card>
    )
  }
}

const mapStateToProps = ({ plant, genus, month, region, careIcon }) => ({
  ...plant,
  ...genus,
  ...month,
  ...region,
  ...careIcon,
  initialValues: {
    ...plant.plant,
    genusCareInfos: plant.genusCareInfos,
    categoryCareInfos: plant.categoryCareInfos,
  },
})

const mapDispatchToProps = {
  ...plantActions,
  ...genusActions,
  ...careInfoActions,
  ...monthActions,
  ...careIconActions,
  ...regionApi,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    validate,
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  })(Plant)
)
