import plantApi from '../api/modules/plant.api'
import {
  GET_PLANTS_SUCCEED,
  GET_PLANT_SUCCEED,
  GETTING_PLANTS,
  GETTING_PLANT,
  UPDATING_PLANT,
  UPDATE_PLANT_SUCCEED,
  GETTING_PLANT_CARE_INFO,
  GET_PLANT_CARE_INFO_SUCCEED,
  CREATING_PLANT,
  ERROR_UPDATING_PLANT,
  ERROR_CREATING_PLANT,
  CREATED_PLANT,
} from '../constants/plant.constants'
import genusCareInfoApi from '../api/modules/genusCareInfo.api'
import categoryCareInfoApi from '../api/modules/categoryCareInfo.api'

export default {
  getAllPlants(params) {
    return async dispatch => {
      dispatch({
        type: GETTING_PLANTS,
      })
      const plants = await plantApi.getAllPlants(params)
      return dispatch({
        type: GET_PLANTS_SUCCEED,
        payload: plants,
      })
    }
  },
  getPlant(objectId) {
    return async dispatch => {
      dispatch({
        type: GETTING_PLANT,
      })
      const plant = await plantApi.getPlant(objectId)
      return dispatch({
        type: GET_PLANT_SUCCEED,
        payload: plant,
      })
    }
  },
  getPlantCareInfo(genusId, categoryId) {
    return async dispatch => {
      dispatch({
        type: GETTING_PLANT_CARE_INFO,
      })
      const genusCareInfos = await genusCareInfoApi.getGenusCareInfos({
        genusId: {
          __type: 'Pointer',
          className: 'Genus',
          objectId: genusId,
        },
      })
      let categoryCareInfos
      if (categoryId) {
        categoryCareInfos = await categoryCareInfoApi.getCategoryCareInfos({
          categoryId: {
            __type: 'Pointer',
            className: 'GenusCategory',
            objectId: categoryId,
          },
        })
      }

      return dispatch({
        type: GET_PLANT_CARE_INFO_SUCCEED,
        payload: {
          genusCareInfos,
          categoryCareInfos,
        },
      })
    }
  },
  updatePlant(plantData) {
    return async dispatch => {
      dispatch({
        type: UPDATING_PLANT,
      })
      let plant = {}
      try {
        plant = await plantApi.updatePlant(plantData)
      } catch (err) {
        return dispatch({ type: ERROR_UPDATING_PLANT, payload: err })
      }
      return dispatch({
        type: UPDATE_PLANT_SUCCEED,
        payload: plant,
      })
    }
  },
  createPlant(values) {
    return async dispatch => {
      dispatch({
        type: CREATING_PLANT,
      })
      let plant = {}
      try {
        plant = await plantApi.createPlant(values)
      } catch (err) {
        return dispatch({ type: ERROR_CREATING_PLANT, payload: err })
      }
      dispatch({
        type: CREATED_PLANT,
        payload: plant,
      })
      return plant.toJSON()
    }
  },
}
