export const getNameFromUser = user => {
  let names = []
  if (user.firstName || user.lastName) {
    if (user.firstName) {
      names.push(user.firstName)
    }
    if (user.lastName) {
      names.push(user.lastName)
    }
  } else {
    if (user.username) {
      names.push(user.username)
    } else if (user.email) {
      names.push(user.email)
    }
  }
  return names.length > 0 ? names.join(' ') : 'unknown'
}
