import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class ExpertChatMessage extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    byline: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
  }

  static defaultProps = {
    message: '',
    align: 'left',
    className: 'bg-primary text-light',
    byline: '',
  }

  state = {
    showByline: false,
  }

  render() {
    const { message, align, className, byline, kind } = this.props

    const divClassname = cx(
      'd-flex',
      `justify-content-${align === 'right' ? 'end' : 'start'}`
    )

    const spanClassname = cx(
      'snapp-message',
      'expert-sent-message',
      kind === 'snappComment' ? 'p-3' : 'p-1 small',
      className
    )

    return (
      <div className={divClassname}>
        <p
          className={spanClassname}
          onClick={() => this.setState({ showByline: !this.state.showByline })}
        >
          {message} <br />
          <small className={cx({ 'd-none': !this.state.showByline })}>
            {byline}
          </small>
        </p>
      </div>
    )
  }
}

export default ExpertChatMessage
