import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'

import './style/index.scss'
import * as serviceWorker from './serviceWorker'
import App from './containers/App'
import Loading from './components/Loading'

const history = createBrowserHistory()

ReactDOM.render(
  <Fragment>
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router
          basename={process.env.REACT_APP_ROUTER_BASENAME}
          history={history}
        >
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </Fragment>,
  document.getElementById('root')
)

serviceWorker.register()
