import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { messaging } from '../api/firebase'

import routes from '../routes/index'
export class App extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    messaging.onMessage(payload => {
      console.log('Message received. ', payload)
      // ...
    })
  }
  
  render() {
    return (
      <Switch>
        {routes.map((route, key) => {
          // check if logged in via presence of token
          if (route.needsAuth && this.props.isLoggedIn) {
            return (
              <Route path={route.path} component={route.component} key={key} />
            )
          } else if (!route.needsAuth) {
            return (
              <Route path={route.path} component={route.component} key={key} />
            )
          } else {
            return <Redirect to={'/login'} key={key} />
          }
        })}
      </Switch>
    )
  }
}

App.propTypes = {
  token: PropTypes.string,
}

const mapStateToProps = ({ auth }) => ({
  ...auth,
})

export default connect(mapStateToProps)(App)
