import snappCommentApi from '../api/modules/snappComment.api'
import {
  GET_SNAPP_COMMENTS_SUCCEED,
  GET_SNAPP_COMMENT_SUCCEED,
  GETTING_SNAPP_COMMENTS,
  GETTING_SNAPP_COMMENT,
  UPDATING_SNAPP_COMMENT,
  UPDATE_SNAPP_COMMENT_SUCCEED,
  SUBSCRIBING_TO_SNAPP_COMMENTS,
  SUBSCRIBED_TO_SNAPP_COMMENTS,
  CREATED_SNAPP_COMMENT_LIVE_QUERY,
  UPDATED_SNAPP_COMMENT_LIVE_QUERY,
  DELETED_SNAPP_COMMENT_LIVE_QUERY,
  GET_SNAPP_COMMENTS_ERROR,
  CREATED_SNAPP_COMMMENT,
  ERROR_CREATING_SNAPP_COMMMENT,
  CREATING_SNAPP_COMMMENT,
} from '../constants/snappComment.constants'

export default {
  getAllSnappComments(params) {
    return async dispatch => {
      dispatch({
        type: GETTING_SNAPP_COMMENTS,
      })
      try {
        const snappComments = await snappCommentApi.getAllSnappComments(params)
        return dispatch({
          type: GET_SNAPP_COMMENTS_SUCCEED,
          payload: snappComments,
        })
      } catch (err) {
        return dispatch({
          type: GET_SNAPP_COMMENTS_ERROR,
          payload: err,
        })
      }
    }
  },
  subscribeToSnappComments(params) {
    return async dispatch => {
      dispatch({
        type: SUBSCRIBING_TO_SNAPP_COMMENTS,
      })

      const liveQuery = await snappCommentApi.subscribeToSnappComments(params)

      liveQuery.on('open', () => {
        dispatch({
          type: SUBSCRIBED_TO_SNAPP_COMMENTS,
        })
      })

      liveQuery.on('create', snappComment => {
        dispatch({
          type: CREATED_SNAPP_COMMENT_LIVE_QUERY,
          payload: snappComment.toJSON(),
        })
      })

      liveQuery.on('update', snappComment => {
        dispatch({
          type: UPDATED_SNAPP_COMMENT_LIVE_QUERY,
          payload: snappComment.toJSON(),
        })
      })

      liveQuery.on('enter', snappComment => {
        dispatch({
          type: CREATED_SNAPP_COMMENT_LIVE_QUERY,
          payload: snappComment.toJSON(),
        })
      })

      liveQuery.on('leave', snappComment => {
        dispatch({
          type: DELETED_SNAPP_COMMENT_LIVE_QUERY,
          payload: snappComment.toJSON(),
        })
      })

      liveQuery.on('delete', snappComment => {
        dispatch({
          type: DELETED_SNAPP_COMMENT_LIVE_QUERY,
          payload: snappComment.toJSON(),
        })
      })
    }
  },
  getSnappComment(objectId) {
    return async dispatch => {
      dispatch({
        type: GETTING_SNAPP_COMMENT,
      })
      const snappComment = await snappCommentApi.getSnappComment(objectId)
      return dispatch({
        type: GET_SNAPP_COMMENT_SUCCEED,
        payload: snappComment,
      })
    }
  },
  updateSnappComment(snappCommentData) {
    return async dispatch => {
      dispatch({
        type: UPDATING_SNAPP_COMMENT,
      })
      const snappComment = snappCommentApi.updateSnappComment(snappCommentData)
      return dispatch({
        type: UPDATE_SNAPP_COMMENT_SUCCEED,
        payload: snappComment,
      })
    }
  },
  createSnappComment(snappComment) {
    return async dispatch => {
      dispatch({ type: CREATING_SNAPP_COMMMENT })
      try {
        const sC = await snappCommentApi.createSnappComment(snappComment)
        dispatch({
          type: CREATED_SNAPP_COMMMENT,
          payload: sC,
        })
      } catch (err) {
        dispatch({
          type: ERROR_CREATING_SNAPP_COMMMENT,
          payload: err,
        })
      }
    }
  },
}
