import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
  Navbar,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  CustomInput,
  Label,
  UncontrolledTooltip,
} from 'reactstrap'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import Helmet from 'react-helmet'
import moment from 'moment'

import Loading from './Loading'
import Layout from '../containers/Layout'
import snappActions from '../actions/snapp.actions'
import userActions from '../actions/user.actions'
import regionApi from '../api/modules/region.api'
import genusApi from '../api/modules/genus.api'
import { snappOrderBys, snappTypes } from '../constants/index'

import spPlantPlaceholder from '../assets/images/sp-plant-placeholder@2x.png'
import { InjectedFormProps, Field, reduxForm, SubmissionError } from 'redux-form';

const now = new Date()

const mapState = (state: any) => ({
  gettingSnapps: state.snapp.gettingSnapps,
  lockedSnapps: state.snapp.lockedSnapps,
  snappsRequestParams: state.snapp.snappsRequestParams,
  snapps: state.snapp.snapps.map((s: any) => ({
    objectId: s.objectId,
    lockedUntil:
      s.beingIdentifiedUntilTime &&
      moment(s.beingIdentifiedUntilTime.iso).fromNow().toString(),
    isLocked:
      s.beingIdentifiedUntilTime &&
      new Date(s.beingIdentifiedUntilTime.iso) > now &&
      s.beingIdentifiedByExpert &&
      state.user.self &&
      s.beingIdentifiedByExpert.objectId === state.user.self.objectId,
    snappImage: getThumbImageFromSnapp(s),
    sendForIdentification:
      s.sendForIdentification && moment(s.sendForIdentification.iso).fromNow(),
    snappUser: s.snappUser && s.snappUser.publicName,
    region: s.region && s.region.regionName,
  })),
  ...state.region,
  ...state.user,
})

const mapDispatch = {
  ...snappActions,
  ...userActions,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  history: any,
  gettingSnapps: boolean,
  snapps: any[],
  snappsRequestParams: any,
  getSnapps: () => any,
  regions: any[],
  getSelf: () => any,
  getOwnExpertDetails: () => any,
  getUserFirebaseToken: () => any,
  lockListSnapp: () => any,
  unlockListSnapp: () => any,
  lockedSnapps: any[],
  self: () => any,
}

const getThumbImageFromSnapp = function (snapp: any) {
  if (snapp.thumbImageUri) {
    return snapp.thumbImageUri
  }
  if (snapp.thumbImage) {
    return snapp.thumbImage.url
  }
  if (snapp.snappImageUri) {
    return snapp.snappImageUri
  }
  if (snapp.snappImage) {
    return snapp.snappImage.url
  }
  return spPlantPlaceholder
}

export const List: React.FC<InjectedFormProps<{}>> = (props: Props) => {
  useEffect(() => {
    (async function func() {
      await props.getSnapps(props.snappsRequestParams || {})
      await props.getSelf()
      await props.getOwnExpertDetails()
      //const result = await Notification.requestPermission()

      //if (result === 'denied') {
        // TODO: show alert for dismissed notifications
      //}
      //if (result === 'default') {
        // TODO: show alert for reenabling notifications
      //}
      //if (result === 'granted') {
        //await props.getUserFirebaseToken()
      //}
    })()
  }, [])

  const handleClickSnapp = (snappId: any) => {
    props.history.push(`/case/${snappId}`)
  }

  const handleChangeOrderBy = async (orderBy: any) => {
    await props.getSnapps({
      orderBy,
    })
  }

  const handleChangeCaseType = async (snappType: any) => {
    await props.getSnapps({
      snappType,
    })
  }

  const handleSelectRegion = async (regions: any) => {
    await props.getSnapps({
      regions,
    })
  }

  const handleSelectGenus = async (genera: any) => {
    await props.getSnapps({
      genera,
    })
  }

  const handleShowMineOnly = async (ev: any) => {
    if (ev.target.checked) {
      await props.getSnapps({ expert: props.self.objectId })
    } else {
      await props.getSnapps({ expert: null })
    }
  }

  const handleShowLockedOnly = async (ev: any) => {
    console.log(ev)
    await props.getSnapps({ lockedOnly: ev.target.checked })
  }

  const handleClickLockSnapp = (snapp: any) => {
    props.lockListSnapp(snapp, props.self)
  }

  const { snapps, gettingSnapps, snappsRequestParams, self } = props
  const userName = (self && self.publicName) || ''
   // const totalEarned = 12.34
  return (
    <form>
      <Layout>
        <Container className="p-3 bg-light">
          <Row className="p-5">
            <Col>
              <h3 className="text-center">
                Welcome back {userName}!
                {/*  You earned £{totalEarned} since your
                last login */}
              </h3>
            </Col>
          </Row>
          <Navbar color="light" sticky="top" className="p-0 m-0">
            <Col>
              <FormGroup className="my-2">
                <Field
                  name="priority"
                  label="Priority"
                  component={() => (
                    <Select
                      type="select"
                      name="priority"
                      id="prioritySelect"
                      value={snappsRequestParams && snappsRequestParams.orderBy}
                      onChange={handleChangeOrderBy}
                      options={snappOrderBys}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="my-2">
                <Field
                  name="caseType"
                  label="Case type"
                  component={() => (
                    <Select
                      type="select"
                      name="caseType"
                      id="caseTypeSelect"
                      value={snappsRequestParams && snappsRequestParams.snappType}
                      onChange={handleChangeCaseType}
                      options={snappTypes}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="my-2">
                <Field
                  name="regionsSelect"
                  label="Regions"
                  component={() => (
                    <AsyncSelect
                      type="switch"
                      id="regionsSelect"
                      name="regionsSelect"
                      placeholder="Regions…"
                      value={snappsRequestParams && snappsRequestParams.regions}
                      onChange={handleSelectRegion}
                      isMulti
                      cacheOptions
                      defaultOptions
                      loadOptions={regionApi.loadRegionOptions}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="my-2">
                <Field
                  name="generaSelect"
                  label="Genera"
                  component={() => (
                    <AsyncSelect
                      type="switch"
                      id="generaSelect"
                      name="generaSelect"
                      placeholder="Genera…"
                      value={snappsRequestParams && snappsRequestParams.genera}
                      onChange={handleSelectGenus}
                      isMulti
                      cacheOptions
                      defaultOptions
                      loadOptions={genusApi.loadGenusOptions}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check className="my-2">
                <Field
                  name="mineOnly"
                  label="Mine only"
                  component={() => (
                    <CustomInput
                      type="switch"
                      id="mineOnlySwitch"
                      name="mineOnly"
                      label="Mine only"
                      checked={
                        Boolean(snappsRequestParams.expert) &&
                        snappsRequestParams.expert === self.objectId
                      }
                      onChange={handleShowMineOnly}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check>
                <Field
                  name="lockedOnly"
                  label="Locked only"
                  component={() => (
                    <CustomInput
                      type="switch"
                      id="lockedOnlySwitch"
                      name="lockedOnly"
                      label="Show locked only"
                      checked={Boolean(snappsRequestParams.lockedOnly)}
                      onChange={handleShowLockedOnly}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Navbar>
          <Row className="py-3 px-3">
            <h4>Waiting for your reply: {snapps && snapps.length}</h4>
          </Row>
          <Row className="position-relative">
            {gettingSnapps && <Loading />}
            {snapps.map((snapp: any, i: number) => {
              return (
                <Col xs="12" md="3" key={i}>
                  <Card className="mb-2">
                    <a href={`/case/${snapp.objectId}`}>
                      <CardImg
                        top
                        width="100%"
                        height="150px"
                        src={snapp.snappImage}
                        alt="Card image cap"
                        style={{ objectFit: 'cover' }}
                      />
                    </a>

                    <CardBody>
                      <p className="text-right m-0">
                        {snapp.sendForIdentification}
                      </p>
                      <p className="d-none text-right text-success m-0">£2.85</p>
                      <a href={`/case/${snapp.objectId}`}>
                        <p className="text-left m-0">
                          {snapp.snappUser}
                          <br />
                          {snapp.region}
                        </p>
                      </a>
                      <FormGroup className="my-0">
                        <Label check></Label>
                        <Field
                          name="lockListSnapp"
                          label="Lock for me"
                          component={() => (
                            <CustomInput
                              id={snapp.objectId}
                              type="switch"
                              name="lockListSnapp"
                              label="Lock for me"
                              checked={snapp.isLocked}
                              onChange={() => {
                                handleClickLockSnapp({
                                  objectId: snapp.objectId,
                                })
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                      {snapp.lockedUntil && (
                        <p>Lock ends {snapp.lockedUntil}</p>
                      )}
                      <div className="text-right m-0">
                        <a href={`/case/${snapp.objectId}`} target="_blank" id={`ref-${snapp.objectId}`}>
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                        <UncontrolledTooltip target={`ref-${snapp.objectId}`}>Open in new tab</UncontrolledTooltip>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Layout>
    </form>
  )
}

const listForm = reduxForm<{}>({
  form: 'list',
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
})(List)

export default connector(listForm)
