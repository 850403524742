import Parse from '../parse'
const Genus = Parse.Object.extend('Genus')

export default {
  async getGenera() {
    let genusQuery = new Parse.Query(Genus)

    const results = await genusQuery.find()

    return results.map(genus => genus.toJSON())
  },

  async loadGenusOptions(inputValue) {
    let genusQuery = new Parse.Query(Genus)

    if (inputValue) {
      genusQuery.matches('genusName', new RegExp(inputValue, 'i'))
    }

    let results = await genusQuery.find()

    return results.map(genus => ({
      value: genus.id,
      label: genus.get('genusName'),
    }))
  },

  async getGenus(objectId) {
    let genusQuery = new Parse.Query(Genus)

    const result = await genusQuery.get(objectId)

    return result.toJSON()
  },
}
