import authApi from '../api/modules/auth.api'
import {
  HIDE_AUTH_ALERT,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGOUT,
  ERROR_LOGGING_OUT,
} from '../constants/auth.constants'

export default {
  login(values) {
    return async dispatch => {
      dispatch({
        type: LOGIN_REQUEST,
      })
      const user = await authApi.login(values)
      return dispatch({
        type: LOGIN_SUCCESS,
        meta: {},
        payload: {
          objectId: user.id,
          sessionToken: user.get('sessionToken'),
        },
      })
    }
  },
  logout() {
    return async dispatch => {
      try {
        await authApi.logout()
        dispatch({
          type: LOGOUT,
        })
      } catch (err) {
        dispatch({
          type: ERROR_LOGGING_OUT,
          payload: err,
        })
      }
    }
  },
  hideAlert() {
    return dispatch => {
      return dispatch({
        type: HIDE_AUTH_ALERT,
      })
    }
  },
}
