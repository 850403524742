import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Media
} from 'reactstrap'
import authActions from '../actions/auth.actions'
// import packageJson from '../../package.json'

import '../style/index.scss'

class Layout extends Component {
  static propTypes = {
    history: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  }

  state = {
    isOpen: false,
  }

  async logout() {
    await this.props.logout()
    // this.props.history.push('/login')
  }

  render() {
    const { history, children, match } = this.props
    return (
      <Container fluid className="p-0">
        <Navbar color="light" expand="md" className="shadow">
          <NavbarBrand href="/">
            <img src="../assets/images/sp-logo-horizontal.png" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {/* <NavItem>
                <NavLink className="text-info">£13.05 locked in posts</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="text-dark">£643.56 earned</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  active={match.path === '/list'}
                  onClick={() => history.push('/list')}
                >
                  lists
                </NavLink>
              </NavItem>
              <NavItem>                                                                                                                                                                                                                                                                                                                                                                                                                  
                <NavLink onClick={() => this.logout()}>Log out</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <div className="h-100">{children}</div>
        
      </Container>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  ...authActions,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))
