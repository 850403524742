import Parse from '../parse'
import { messaging } from '../firebase'
const ExpertDetails = Parse.Object.extend('ExpertDetails')

export default {
  getSelf() {
    return Parse.User.current()
  },
  async updateSelf(self) {
    const currentUser = Parse.User.current()
    await currentUser.save(self)
    return currentUser
  },
  async getFirebaseToken() {
    try {
      const currentToken = await messaging.getToken()
      if (currentToken) {
        const currentUser = await this.updateSelf({
          firebaseWebToken: currentToken,
        })
        return currentUser
      } else {
        // Show permission request.
        console.log(
          'No Instance ID token available. Request permission to generate one.'
        )
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err)
    }
  },
  async subscribeToUser(objectId) {
    let userQuery = new Parse.Query(Parse.User)
    userQuery.equalTo('objectId', objectId)
    return userQuery.subscribe()
  },
  async getExpertDetails() {
    const currentUser = await Parse.User.current()
    const expertDetailsQuery = new Parse.Query(ExpertDetails)
    return expertDetailsQuery.equalTo('user', currentUser).first()
  },
  async search(text) {
    const userQuery = new Parse.Query(Parse.User)
    userQuery
      .equalTo('email', text)
      .select(['firstName', 'lastName', 'email', 'username'])
    try {
      const users = await userQuery.find()
      return users.map(userParse => {
        const user = userParse.toJSON()
        const firstName = user.firstName ? user.firstName + ' ' : ''
        const lastName = user.lastName ? user.lastName + ' ' : ''
        const email = user.email ? user.email : user.username
        const label = `${firstName}${lastName}${email}`
        return {
          value: user.objectId,
          label,
        }
      })
    } catch (err) {
      console.error(err)
      return []
    }
  },
  async issueOneCreditForUser(userId) {
    return Parse.Cloud.run('issueOneCreditForUser', { userId })
  },
}
