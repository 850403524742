import { applyMiddleware, createStore, compose } from 'redux'
import { persistStore, persistReducer, createMigrate, PersistedState } from 'redux-persist'
import localforage from 'localforage'
import localstorage from 'redux-persist/lib/storage'
import rootReducer from '../reducers'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = (window && (window as any)).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const storage =
  process.env.NODE_ENV === 'production' ? localforage : localstorage

const resetState = (state: PersistedState) => {
  console.debug('Migration running')
  return {} as PersistedState
}

const migrations = {
  1: resetState,
  2: resetState,
}

const persistConfig = {
  key: 'sp-expert-board',
  version: 1,
  storage,
  migrate: createMigrate(migrations, { debug: false })
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, promise))
)

let persistor = persistStore(store)

export { store, persistor }
