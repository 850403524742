import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import {
  reduxForm,
  Field,
  formValueSelector,
  propTypes as reduxFormPropTypes,
  SubmissionError,
} from 'redux-form'

import { Button, Row, Col } from 'reactstrap'

import plantApi from '../api/modules/plant.api'
import CustomAsyncSelect from './form/CustomAsyncSelect'
import snappActions from '../actions/snapp.actions'
import plantActions from '../actions/plant.actions'

const form = 'identify-plant'
const selector = formValueSelector(form)

const validatePlantSelection = (val) => {
  return val ? undefined : 'You must select a plant'
}

const onSubmit = async (values, dispatch, props) => {
  let plantObjectId = values.plant.value
  let userObjectId = values.snapp.snappUser.objectId
  let snappObjectId = values.snapp.objectId
  if (values.addingPlant) {
    let plant = {}
    try {
      plant = await props.createPlant({
        latinName: values.plant.value,
      })
      plantObjectId = plant.objectId
    } catch (err) {
      throw new SubmissionError({
        _error: 'Error saving new plant',
      })
    }
    try {
      await props.assigningPlantToSnapp({
        plantObjectId,
        userObjectId,
        snappObjectId,
      })
    } catch (err) {
      throw new SubmissionError({
        _error: 'Error assigning plant',
      })
    }
  }
  try {
    await props.updateSnapp(values)
  } catch (err) {
    throw new SubmissionError({
      _error: 'Error updating snapp',
    })
  }
}

class IdentifyPlant extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    addingPlantValue: PropTypes.bool.isRequired,
  }

  state = {
    dropdownOpen: false,
  }

  handleGetPlants = query => plantApi.search(query)

  handleOnCreateNewPlant = inputValue => {
    this.props.change('addingPlant', true)
    this.props.change('plant', { label: inputValue, value: inputValue })
  }

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      submitting,
      addingPlantValue,
    } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs='12' md='6'>
            <h3>Identification details</h3>

            <Field
              name="addingPlant"
              component="input"
              type="checkbox"
              className="d-none"
            />

            <Field
              name="plant"
              component={CustomAsyncSelect}
              validate={validatePlantSelection}
              loadOptions={this.handleGetPlants}
              onCreateOption={this.handleOnCreateNewPlant}
            />
          </Col>

          <Col xs='12' md='6'>
            <Button
              type="submit"
              color="success"
              disabled={pristine || invalid || submitting}
            >
              {submitting ? (
                <i className="fas fa-asterisk fa-spin"></i>
              ) : addingPlantValue ? (
                'ADD NEW PLANT'
              ) : (
                'CONFIRM IDENTIFICATION'
              )}
            </Button>
          </Col>
        </Row>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  addingPlantValue: selector(state, 'addingPlant') || false,
  initialValues: {
    plant: null,
    addingPlant: false,
    snapp: state.snapp.snapp,
  },
})

const mapDispatchToProps = {
  ...plantActions,
  ...snappActions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(IdentifyPlant)
)
