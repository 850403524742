import {
  GETTING_PLANTS,
  GET_PLANTS_SUCCEED,
  GETTING_PLANT,
  GET_PLANT_SUCCEED,
  UPDATING_PLANT,
  UPDATE_PLANT_SUCCEED,
  GET_PLANT_CARE_INFO_SUCCEED,
  GETTING_PLANT_CARE_INFO,
  CREATING_PLANT,
  CREATED_PLANT,
  ERROR_CREATING_PLANT,
  ERROR_UPDATING_PLANT,
} from '../constants/plant.constants'

const initialState = {
  plants: [],
  gettingPlants: false,
  plant: {},
  gettingPlant: false,
  genusCareInfos: [],
  gettingCareInfos: false,
  creatingPlant: false,
  error: false,
  errorMessage: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING_PLANTS:
      return {
        ...state,
        gettingPlants: true,
      }
    case GET_PLANTS_SUCCEED:
      return {
        ...state,
        plants: action.payload,
        gettingPlants: false,
      }
    case GETTING_PLANT:
      return {
        ...state,
        plant: initialState.plant,
        gettingPlant: true,
      }
    case GET_PLANT_SUCCEED:
      return {
        ...state,
        plant: action.payload,
        gettingPlant: false,
      }
    case UPDATING_PLANT:
      return {
        ...state,
        updatingPlant: true,
      }
    case UPDATE_PLANT_SUCCEED:
      return {
        ...state,
        plant: action.payload,
        updatingPlant: false,
      }
    case ERROR_UPDATING_PLANT:
      return {
        ...state,
        updatingPlant: false,
        error: true,
        errorMessage: action.payload,
      }
    case GETTING_PLANT_CARE_INFO:
      return {
        ...state,
        genusCareInfos: [],
        gettingCareInfos: true,
      }
    case GET_PLANT_CARE_INFO_SUCCEED:
      return {
        ...state,
        genusCareInfos: action.payload.genusCareInfos,
        categoryCareInfos: action.payload.categoryCareInfos,
        gettingCareInfos: false,
      }
    case CREATING_PLANT:
      return {
        ...state,
        error: false,
        creatingPlant: true,
      }
    case CREATED_PLANT:
      return {
        ...state,
        error: false,
        creatingPlant: false,
        plant: action.payload,
      }
    case ERROR_CREATING_PLANT:
      return {
        error: true,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}
