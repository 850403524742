import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  reduxForm,
  propTypes as reduxFormPropTypes,
  SubmissionError,
  Field,
} from 'redux-form'
import ExpertChatMessage from './ExpertChatMessage'
import {
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Toast,
  ToastHeader,
  ToastBody,
} from 'reactstrap'
import { getNameFromUser } from '../utils/index'
import moment from '../../node_modules/moment/moment'
import { sentenceCase } from 'change-case'
import Loading from './Loading'
import ExpertChatInput from './ExpertChatInput'
import ExpertChatButton from './ExpertChatButton'
import snappCommentActions from '../actions/snappComment.actions'

const predefinedMessages = ['Can I help you?', 'This is not a plant']

const form = 'expert-chat'

const validate = values => {
  const errors = {}
  if (!values.comments) {
    errors.comments = 'You must type a message'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    props.createSnappComment(values)
  } catch (err) {
    throw new SubmissionError({
      _error: 'Problem sending message',
    })
  }
}

class ExpertChat extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    messages: PropTypes.array,
    gettingSnappComments: PropTypes.bool.isRequired,
    self: PropTypes.object.isRequired,
    createSnappComment: PropTypes.func.isRequired,
    snappCommentError: PropTypes.bool.isRequired,
    snappCommentErrorMessage: PropTypes.string,
  }

  state = {
    templateDropdownOpen: false,
  }

  render() {
    const {
      change,
      invalid,
      submitting,
      pristine,
      handleSubmit,
      messages,
      gettingSnappComments,
      submitFailed,
      error,
      snappCommentError,
      snappCommentErrorMessage,
    } = this.props

    return (
      <div>
        <div id="message-history" className="bg-light py-3 px-3">
          {gettingSnappComments && <Loading absolutePosition={false} />}
          {messages &&
            messages.map((message, index) => {
              return <ExpertChatMessage key={index} {...message} />
            })}
        </div>

        <form id="new-message-form" className="mt-3" onSubmit={handleSubmit}>
          <FormGroup>
            <InputGroup>
              <InputGroupButtonDropdown
                addonType="prepend"
                isOpen={this.state.templateDropdownOpen}
                toggle={() =>
                  this.setState({
                    templateDropdownOpen: !this.state.templateDropdownOpen,
                  })
                }
              >
                <DropdownToggle color="light" caret>
                  <i className="fas fa-pencil-alt"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Predefined messages</DropdownItem>
                  {predefinedMessages.map((predefinedMessage, i) => (
                    <DropdownItem
                      key={i}
                      onClick={() => change('comments', predefinedMessage)}
                    >
                      {predefinedMessage}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </InputGroupButtonDropdown>
              <InputGroupAddon addonType="prepend">
                <Button color="light">
                  <i className="fas fa-image"></i>
                </Button>
              </InputGroupAddon>
              <Field
                id="message-composer"
                name="comments"
                component={(props) => (
                  <>
                    <ExpertChatInput { ...props } />
                  </>
                )}
              />
              <ExpertChatButton
                pristine={pristine}
                submitting={submitting}
                invalid={invalid}
              />
            </InputGroup>
          </FormGroup>
        </form>
        <Toast
          color="danger"
          isOpen={submitFailed}
          className="fixed-bottom mb-3 ml-3"
        >
          <ToastHeader>Error</ToastHeader>
          <ToastBody>{error || 'Sending message failed'}</ToastBody>
        </Toast>
        <Toast
          color="danger"
          isOpen={snappCommentError}
          className="fixed-bottom mb-3 ml-3"
        >
          <ToastHeader>Error</ToastHeader>
          <ToastBody>
            {snappCommentErrorMessage || 'Getting messages failed'}
          </ToastBody>
        </Toast>
      </div>
    )
  }
}

const mapStateToProps = ({
  snappComments,
  snappLedger,
  user: { self },
  snapp: {
    snapp: { objectId: snappId, snappUser },
  },
}) => {
  let messages = [
    ...snappComments.snappComments.map(sC => {
      let byline = ''
      if (sC.expert) {
        byline = getNameFromUser(sC.expert)
      }
      if (sC.user) {
        byline = getNameFromUser(sC.user)
      }
      byline += ' ' + moment(sC.createdAt).fromNow()
      return {
        align: sC.expert && sC.expert.objectId ? 'right' : 'left',
        className:
          sC.expert && sC.expert.objectId === self.objectId
            ? 'bg-primary text-light'
            : 'bg-secondary text-light',
        message: sC.comments || '',
        byline,
        kind: 'snappComment',
        createdAt: new Date(sC.createdAt),
      }
    }),
    ...snappLedger.snappLedgers.map(sL => {
      const byline = moment(sL.createdAt).fromNow()
      return {
        align: 'right',
        className: 'bg-dark text-light',
        message: `${sentenceCase(sL.action)} ${byline}`,
        byline,
        kind: 'snappLedger',
        createdAt: new Date(sL.createdAt),
      }
    }),
  ]
  
  return {
    messages: messages.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1
      }
      if (a.createdAt > b.createdAt) {
        return 1
      }
      return 0
    }),
    self,
    snappCommentError: snappComments.error,
    snappCommentErrorMessage: snappComments.errorMessage,
    gettingSnappComments: snappComments.gettingSnappComments,
    initialValues: {
      comments: '',
      user: snappUser,
      expert: {
        __type: 'Pointer',
        className: '_User',
        objectId: self.objectId,
      },
      snapp: {
        __type: 'Pointer',
        className: 'Snapp',
        objectId: snappId,
      },
    },
  }
}

const mapDispatchToProps = {
  ...snappCommentActions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    onSubmit,
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  })(ExpertChat)
)
