import Parse from '../parse'
const CareInfo = Parse.Object.extend('CareInfo')

export default {
  async getCareInfos(params) {
    let careInfoQuery = new Parse.Query(CareInfo)
    if (params.limit) {
      careInfoQuery.limit(params.limit).skip(params.skip)
    }

    if (params.plant) {
      careInfoQuery.equalTo('plant', params.plant.value)
    }

    if (params.genera) {
      careInfoQuery.equalTo('genera.objectId', params.genera.value)
    }

    if (params.region) {
      careInfoQuery.equalTo('region', params.region.value)
    }

    careInfoQuery
      .include([
        'genera',
        'locations',
        'care',
        'plant',
        'category',
        'icon',
        'region'
      ])
      .withCount()

    const results = await careInfoQuery.find()

    return {
      results: results.results.map(care => care.toJSON()),
      count: results.count
    }
  },

  async getCareInfo(objectId) {
    let careInfoQuery = new Parse.Query(CareInfo)

    const result = await careInfoQuery.get(objectId)

    return result.toJSON()
  },

  async updateCareInfo(careInfo) {
    const careInfoQuery = new Parse.Query(CareInfo)
    const careInfoUpdate = await careInfoQuery.get(careInfo.objectId)
    await careInfoUpdate.save(careInfo)
    return careInfoUpdate.toJSON()
  },

  async createCareInfo(careInfo) {
    const care = new CareInfo()
    await care.save(careInfo)
  }
}
