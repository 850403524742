export const GET_SNAPP_LEDGERS_SUCCEED = 'GET_SNAPP_LEDGERS_SUCCEED'
export const GETTING_SNAPP_LEDGERS = 'GETTING_SNAPP_LEDGERS'
export const GET_SNAPP_LEDGERS_ERROR = 'GET_SNAPP_LEDGERS_ERROR'
export const GET_SNAPP_LEDGER_SUCCEED = 'GET_SNAPP_LEDGER_SUCCEED'
export const SET_SNAPP_LEDGERS_REQUEST_PARAMS =
  'SET_SNAPP_LEDGERS_REQUEST_PARAMS'
export const SUBSCRIBING_TO_SNAPP_LEDGERS = 'SUBSCRIBING_TO_SNAPP_LEDGERS'
export const SUBSCRIBED_TO_SNAPP_LEDGERS = 'SUBSCRIBED_TO_SNAPP_LEDGERS'
export const CREATED_SNAPP_LEDGER_LIVE_QUERY = 'CREATED_SNAPP_LEDGER_LIVE_QUERY'
export const UPDATED_SNAPP_LEDGER_LIVE_QUERY = 'UPDATED_SNAPP_LEDGER_LIVE_QUERY'
export const DELETED_SNAPP_LEDGER_LIVE_QUERY = 'DELETED_SNAPP_LEDGER_LIVE_QUERY'
export const GETTING_SNAPP_LEDGER = 'GETTING_SNAPP_LEDGER'
export const UPDATING_SNAPP_LEDGER = 'UPDATING_SNAPP_LEDGER'
export const UPDATE_SNAPP_LEDGER_SUCCEED = 'UPDATE_SNAPP_LEDGER_SUCCEED'
