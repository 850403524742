import {
  GETTING_CARE_INFOS,
  GET_CARE_INFOS_SUCCEED,
  GETTING_CARE_INFO,
  GET_CARE_INFO_SUCCEED,
  UPDATING_CARE_INFO,
  UPDATE_CARE_SUCCEED,
  CREATING_CARE_INFO,
  CREATED_CARE_INFO,
  ERROR_UPDATING_CARE_INFO,
  ERROR_CREATING_CARE_INFO,
  SET_CARE_INFOS_REQUEST_PARAMS,
} from '../constants/careInfo.constants'

const initialState = {
  careInfos: [],
  gettingCareInfos: false,
  careInfo: {},
  gettingCareInfo: false,
  count: null,
  updatingCareInfo: false,
  creatingCareInfo: false,
  error: false,
  errorMessage: '',
  careInfoRequestParams: {
    plants: [],
    genera: [],
    categories: [],
    regions: [],
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING_CARE_INFOS:
      return {
        ...state,
        gettingCareInfos: true,
      }
    case GET_CARE_INFOS_SUCCEED:
      return {
        ...state,
        careInfos: action.payload.results,
        count: action.payload.count,
        gettingCareInfos: false,
      }
    case GETTING_CARE_INFO:
      return {
        ...state,
        gettingCareInfo: true,
      }
    case GET_CARE_INFO_SUCCEED:
      return {
        ...state,
        careInfo: action.payload,
        gettingCareInfo: false,
      }
    case UPDATING_CARE_INFO:
      return {
        ...state,
        updatingCareInfo: true
      }
    case UPDATE_CARE_SUCCEED:
      return {
        ...state,
        careInfo: action.payload,
        updatingCareInfo: false
      }
    case ERROR_UPDATING_CARE_INFO:
      return {
        ...state,
        updatingCareInfo: false,
        error: true,
        errorMessage: action.payload
      }
    case CREATING_CARE_INFO:
      return {
        ...state,
        error: false,
        creatingCareInfo: true
      }
    case CREATED_CARE_INFO:
      return {
        ...state,
        error: false,
        creatingCareInfo: false,
        careInfo: action.payload
      }
    case ERROR_CREATING_CARE_INFO:
      return {
        error: true,
        errorMessage: action.payload
      }
    case SET_CARE_INFOS_REQUEST_PARAMS:
      return {
        ...state,
        careInfoRequestParams: {
          ...state.careInfoRequestParams,
          ...action.payload
        }
      }
    default:
      return state
  }
}
