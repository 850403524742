import PropTypes from 'prop-types'
// import FontAwesome from 'react-fontawesome'
import GoogleMapReact from 'google-map-react'
import React, { Component } from 'react'

class SnappLocation extends Component {
  static defaultProps = {
    center: {
      lat: 41.902782,
      lng: 12.496365,
    },
    zoom: 11,
  }

  render() {
    const center = {
      lat: this.props.lat,
      lng: this.props.lng,
    }

    return (
      <div style={{ height: '75vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
          defaultCenter={center}
          defaultZoom={this.props.zoom}
        >
          {/* <i
            className="fas fa-map-marker-alt color-red fa-5x"
            name="map-marker-alt"
            lat={center.lat}
            lng={center.lng}
          ></i> */}
        </GoogleMapReact>
      </div>
    )
  }
}

SnappLocation.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
}

export default SnappLocation
