export const GETTING_SELF = 'GETTING_SELF'

export const UPDATING_SELF = 'UPDATING_SELF'
export const UPDATED_SELF = 'UPDATED_SELF'
export const ERROR_UPDATING_SELF = 'ERROR_UPDATING_SELF'

export const GETTING_FIREBASE_TOKEN = 'GETTING_FIREBASE_TOKEN'
export const GOT_FIREBASE_TOKEN = 'GOT_FIREBASE_TOKEN'
export const ERROR_GETTING_FIREBASE_TOKEN = 'ERROR_GETTING_FIREBASE_TOKEN'

export const SUBSCRIBING_TO_USER = 'SUBSCRIBING_TO_USER'
export const SUBSCRIBED_TO_USER = 'SUBSCRIBED_TO_USER'
export const GET_SELF_SUCCEED = 'GET_SELF_SUCCEED'
export const GET_SELF_ERROR = 'GET_SELF_ERROR'
export const GETTING_OWN_EXPERT_DETAILS = 'GETTING_OWN_EXPERT_DETAILS'
export const GET_OWN_EXPERT_DETAILS = 'GET_OWN_EXPERT_DETAILS'
export const GET_OWN_EXPERT_DETAILS_ERROR = 'GET_OWN_EXPERT_DETAILS_ERROR'
export const ISSUING_ONE_CREDIT_FOR_USER = 'ISSUING_ONE_CREDIT_FOR_USER'
export const ISSUED_ONE_CREDIT_FOR_USER = 'ISSUED_ONE_CREDIT_FOR_USER'
export const ISSUE_ONE_CREDIT_FOR_USER_ERROR = 'ISSUE_ONE_CREDIT_FOR_USER_ERROR'
