import genusApi from '../api/modules/genus.api'
import {
  GET_GENERA_SUCCEED,
  GETTING_GENERA_OPTIONS,
  GET_GENERA_OPTIONS_SUCCEED,
} from '../constants/genus.constants'

export default {
  getGenera(params) {
    return async dispatch => {
      const genera = await genusApi.getGenera(params)
      dispatch({
        type: GET_GENERA_SUCCEED,
        payload: genera,
      })
      return genera
    }
  },
  loadGenusOptions(inputValue) {
    return async dispatch => {
      dispatch({
        type: GETTING_GENERA_OPTIONS,
      })
      const options = await genusApi.loadGenusOptions(inputValue)
      dispatch({ type: GET_GENERA_OPTIONS_SUCCEED })
      return options
    }
  },
}
