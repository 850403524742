import {
  GET_REGIONS_SUCCEED,
  SET_REGIONS_REQUEST_PARAMS,
} from '../constants/region.constants'

const initialState = {
  regions: [],
  regionRequestParams: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGIONS_SUCCEED:
      return {
        ...state,
        regions: action.payload,
      }
    case SET_REGIONS_REQUEST_PARAMS:
      return {
        ...state,
        regionRequestParams: {
          ...state.regionRequestParams,
          ...action.payload,
        },
      }
    default:
      return state
  }
}
