import React from 'react'
import { connect } from 'react-redux'
import { Field, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form'
import { Card, Button, CardBody, Col, Navbar, Container } from 'reactstrap'
import CustomTextInput from './CustomTextInput';
import CustomAsyncSelect from './CustomAsyncSelect';
import careInfoActions from '../../actions/careInfo.actions';
import genusApi from '../../api/modules/genus.api';
import Layout from '../../containers/Layout';

interface CareInfoFormProps {
  onSubmit: (values: any) => void
}

const mapStateToProps = ({ plant, genus, region }: any) => ({
  ...plant,
  ...genus,
  ...region
})

const mapDispatch = {
  ...careInfoActions
}

const connector = connect(mapStateToProps, mapDispatch)

const validate = (values: any) => {
  let errors: any = {
  }
  if (!values.genus) {
    errors.genus = 'Required'
  }
  return errors
}

const onSubmit = async (values: any, dispatch: any, props: any) => {
  try {
    await props.updateCareInfo(values)
  } catch (err) {
    throw new SubmissionError({
      _error: err.message
    })
  }
}

export const CareInfoForm: React.FC<CareInfoFormProps & InjectedFormProps<{}, CareInfoFormProps>> = (props: any) => {
  const { handleSubmit, submitting, pristine, invalid,  } = props
  
  return (
    <form onSubmit={handleSubmit}>
      <Layout>
        <Container className="p-3 bg-light">
          <Navbar color="light" sticky="top" className="p-0 m-0">
            <Col sm="12">
              <Card>
                <CardBody>
                    <Col xs="12" lg="12">
                      <Field
                        name="schedule"
                        label="Schedule"
                        component={CustomTextInput}
                      />
                      <Field
                        name="care_notification"
                        label="Care notification"
                        component={CustomTextInput}
                      />
                      <Field
                        name="care_info"
                        label="Care info"
                        component={CustomTextInput}
                      />
                      <Field
                        name="plants"
                        label="Plants"
                        component={CustomAsyncSelect}
                        loadOptions={() => {}}
                        isLoading={false}
                      />
                      <Field
                        name="genera"
                        label="Genera"
                        format={(value: any) => 
                          value && {
                            value: value.objectId,
                            label: value.genusName
                          }  
                        }
                        normalize={(value: any, previousValue: any) => ({
                          ...previousValue,
                          objectId: value.value,
                          generaName: value.label
                        })}
                        component={CustomAsyncSelect}
                        loadOptions={genusApi.loadGenusOptions}
                        isLoading={false}
                      />
                      <Field
                        name="categories"
                        label="Categories"
                        component={CustomAsyncSelect}
                        loadOptions={() => {}}
                        isLoading={false}
                      />
                    </Col>
                    <Button
                      type="submit"
                      color="success"
                      disabled={pristine || submitting || invalid}
                    >
                      {submitting ? (<i className="fas fa-asterisk fa-spin"></i>)
                      : ('UPDATE CARE INFO')}
                    </Button>
                </CardBody>
              </Card>
            </Col>
          </Navbar>
        </Container>
      </Layout>
    </form>
  )
}

const careInfoForm = reduxForm<{}, CareInfoFormProps>({
  form: 'care-info',
  onSubmit,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
})(CareInfoForm)

export default connector(careInfoForm)
