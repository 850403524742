import {
  LOGOUT,
  ERROR_LOGGING_OUT,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  HIDE_AUTH_ALERT,
} from '../constants/auth.constants'

const initialState = {
  isSigningUp: false,
  isLoggingIn: false,
  isLoggedIn: false,
  token: null,
  userId: null,
  error: false,
  errorMessage: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        isSigningUp: true,
      }

    case SIGNUP_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isSigningUp: false,
        userId: action.payload.objectId,
        token: action.payload.sessionToken,
      }

    case SIGNUP_FAILURE:
      return {
        ...state,
        isSigningUp: false,
      }

    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userId: action.payload.objectId,
        token: action.payload.sessionToken,
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload.message ? true : false,
        errorMessage: action.payload.message,
        isLoggedIn: false,
        isLoggingIn: false,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        error: false,
      }
    case ERROR_LOGGING_OUT:
      return {
        ...state,
        isLoggedIn: false,
        error: true,
        errorMessage: action.payload.message,
      }
    case HIDE_AUTH_ALERT:
      return {
        ...state,
        error: false,
        errorMessage: '',
      }
    default:
      return state
  }
}
