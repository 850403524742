// parse client
import Parse from 'parse'

Parse.initialize(
  process.env.REACT_APP_PARSE_SERVER_APP_ID,
  process.env.REACT_APP_JAVASCRIPT_KEY
)
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL

Parse.LiveQuery.on('open', () => {
  console.info('established livequery connection')
})

Parse.LiveQuery.on('close', () => {
  console.info('close livequery connection')
})

Parse.LiveQuery.on('error', err => {
  console.error(err)
})

Parse.LiveQuery.open()

export default Parse
