import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  InputGroup,
  InputGroupAddon,
  Input,
  FormGroup,
  FormFeedback,
  Label,
} from 'reactstrap'

class CustomTextInput extends Component {
  static propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    icon: PropTypes.string,
    label: PropTypes.string,
  }

  render() {
    const {
      meta: { valid, error } = {},
      input,
      icon,
      label,
      ...props
    } = this.props
    return (
      <FormGroup>
        {label && (
          <Label for={input.name} className="text-secondary">
            {label}
          </Label>
        )}
        <InputGroup className="mb-3">
          {icon && iconAddOn(icon)}
          <Input {...input} {...props} />
          <FormFeedback valid={valid} tooltip>
            {error}
          </FormFeedback>
        </InputGroup>
      </FormGroup>
    )
  }
}

export default CustomTextInput

function iconAddOn(icon) {
  return (
    <InputGroupAddon addonType="prepend">
      <i className={icon}></i>
    </InputGroupAddon>
  )
}
