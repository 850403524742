import {
  GET_MONTHS_SUCCEED,
  GETTING_MONTHS,
  GETTING_MONTH,
  GET_MONTH_SUCCEED,
} from '../constants/month.constants'

const initialState = {
  months: [],
  gettingMonths: false,
  month: {},
  gettingMonth: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING_MONTHS:
      return {
        ...state,
        gettingMonth: true,
      }
    case GET_MONTHS_SUCCEED:
      return {
        ...state,
        months: action.payload,
        gettingMonth: false,
      }
    case GETTING_MONTH:
      return {
        ...state,
        gettingMonth: true,
      }
    case GET_MONTH_SUCCEED:
      return {
        ...state,
        month: action.payload,
        gettingMonth: false,
      }
    default:
      return state
  }
}
