import Parse from '../parse'
const CategoryCareInfo = Parse.Object.extend('CategoryCareInfo')

export default {
  async getCategoryCareInfos(params) {
    let categoryCareInfoQuery = new Parse.Query(CategoryCareInfo)

    if (params.genusId) {
      categoryCareInfoQuery.equalTo('genusId', params.genusId)
    }

    categoryCareInfoQuery
      .include('monthId')
      .include('regions')
      .include('icon')

    const results = await categoryCareInfoQuery.find()

    return results.map(genus => genus.toJSON())
  },

  async getCategoryCareInfo(objectId) {
    let categoryCareInfoQuery = new Parse.Query(CategoryCareInfo)

    const result = await categoryCareInfoQuery.get(objectId)

    return result.toJSON()
  },
}
