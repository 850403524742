import React from 'react'
import {
  InputGroupAddon,
  Button
} from 'reactstrap'

interface ExpertChatButtonProps {
  invalid: boolean;
  submitting: boolean;
  pristine: boolean;
}

const ExpertChatButton = (props: ExpertChatButtonProps) => {
  const { invalid, submitting, pristine } = props

  return (
    <InputGroupAddon addonType="append">
      <Button
        type="submit"
        color={
          invalid || submitting || pristine ? 'secondary' : 'primary'
        }
        disabled={invalid || submitting || pristine}
      >
        <i className="fas fa-paper-plane"></i>
      </Button>
    </InputGroupAddon>
  )
}

export default ExpertChatButton
