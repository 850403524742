import userApi from '../api/modules/user.api'
import {
  GET_SELF_SUCCEED,
  GET_OWN_EXPERT_DETAILS,
  GET_OWN_EXPERT_DETAILS_ERROR,
  GETTING_OWN_EXPERT_DETAILS,
  GET_SELF_ERROR,
  ISSUING_ONE_CREDIT_FOR_USER,
  ISSUE_ONE_CREDIT_FOR_USER_ERROR,
  ISSUED_ONE_CREDIT_FOR_USER,
  SUBSCRIBING_TO_USER,
  SUBSCRIBED_TO_USER,
  UPDATING_SELF,
  UPDATED_SELF,
  ERROR_UPDATING_SELF,
  GETTING_FIREBASE_TOKEN,
  GOT_FIREBASE_TOKEN,
  ERROR_GETTING_FIREBASE_TOKEN,
} from '../constants/user.constants'
import { LOGOUT } from '../constants/auth.constants'

export default {
  getSelf() {
    return async dispatch => {
      let user = {}
      try {
        user = await userApi.getSelf()
      } catch (err) {
        if (err.message === 'unauthorized') {
          return dispatch({
            type: LOGOUT,
          })
        }
        return dispatch({
          type: GET_SELF_ERROR,
          payload: err,
        })
      }
      await this.subscribeToUser(user.id)
      return dispatch({
        type: GET_SELF_SUCCEED,
        payload: user.toJSON(),
      })
    }
  },
  updateSelf(self) {
    return async dispatch => {
      dispatch({ type: UPDATING_SELF })
      try {
        const user = await userApi.updateSelf(self)
        dispatch({ type: UPDATED_SELF, payload: user })
      } catch (err) {
        dispatch({
          type: ERROR_UPDATING_SELF,
          payload: err,
        })
      }
    }
  },
  getUserFirebaseToken() {
    return async dispatch => {
      dispatch({type: GETTING_FIREBASE_TOKEN})
      try {
        const currentUser = await userApi.getFirebaseToken()
        dispatch({type: GOT_FIREBASE_TOKEN, payload: currentUser})
      } catch (err) {
        dispatch({type: ERROR_GETTING_FIREBASE_TOKEN, payload: err})
      }
    }
  },
  subscribeToUser(objectId) {
    return async dispatch => {
      dispatch({
        type: SUBSCRIBING_TO_USER,
      })

      const liveQuery = await userApi.subscribeToUser(objectId)

      liveQuery.on('open', () => {
        dispatch({
          type: SUBSCRIBED_TO_USER,
        })
      })

      liveQuery.on('error', err => {
        console.error(err)
      })
    }
  },
  getOwnExpertDetails() {
    return async dispatch => {
      dispatch({
        type: GETTING_OWN_EXPERT_DETAILS,
      })
      let expertDetails = {}
      try {
        expertDetails = await userApi.getExpertDetails()
      } catch (err) {
        if (err.message === 'unauthorized') {
          return dispatch({
            type: LOGOUT,
          })
        }
        return dispatch({
          type: GET_OWN_EXPERT_DETAILS_ERROR,
          payload: err.message,
        })
      }
      return dispatch({
        type: GET_OWN_EXPERT_DETAILS,
        payload: {
          id: expertDetails.id,
          expertRegions: expertDetails.get('expertRegions'),
          accountName: expertDetails.get('accountName'),
          snappIdentifiedCount: expertDetails.get('snappIdentifiedCount'),
        },
      })
    }
  },
  issueOneCreditForUser(userId) {
    return async dispatch => {
      dispatch({
        type: ISSUING_ONE_CREDIT_FOR_USER,
      })
      try {
        await userApi.issueOneCreditForUser(userId)
        dispatch({
          type: ISSUED_ONE_CREDIT_FOR_USER,
        })
      } catch (err) {
        return dispatch({
          type: ISSUE_ONE_CREDIT_FOR_USER_ERROR,
          payload: err.message,
        })
      }
    }
  },
}
