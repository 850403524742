import genusCareInfoApi from '../api/modules/genusCareInfo.api'
import {
  GET_GENUS_CARE_INFOS_SUCCEED,
  GETTING_GENUS_CARE_INFOS,
  GETTING_GENUS_CARE_INFO,
  GET_GENUS_CARE_INFO_SUCCEED,
} from '../constants/genusCareInfo.constants'

export default {
  getGenusCareInfos(params) {
    return async dispatch => {
      dispatch({
        type: GETTING_GENUS_CARE_INFOS,
      })
      const genusCareInfos = await genusCareInfoApi.getGenusCareInfos(params)
      dispatch({
        type: GET_GENUS_CARE_INFOS_SUCCEED,
        payload: genusCareInfos,
      })
      return genusCareInfos
    }
  },
  getGenusCareInfo(objectId) {
    return async dispatch => {
      dispatch({ type: GETTING_GENUS_CARE_INFO })
      const genusCareInfo = await genusCareInfoApi.getGenusCareInfo(objectId)
      dispatch({
        type: GET_GENUS_CARE_INFO_SUCCEED,
        payload: genusCareInfo,
      })
      return genusCareInfo
    }
  },
}
