import {
  GET_SELF_SUCCEED,
  GET_OWN_EXPERT_DETAILS,
  GET_OWN_EXPERT_DETAILS_ERROR,
  GETTING_OWN_EXPERT_DETAILS,
  GET_SELF_ERROR,
  GETTING_SELF,
  ISSUING_ONE_CREDIT_FOR_USER,
  ISSUED_ONE_CREDIT_FOR_USER,
  ISSUE_ONE_CREDIT_FOR_USER_ERROR,
  SUBSCRIBING_TO_USER,
  SUBSCRIBED_TO_USER,
  UPDATING_SELF,
  UPDATED_SELF,
  ERROR_UPDATING_SELF,
  GETTING_FIREBASE_TOKEN,
  GOT_FIREBASE_TOKEN,
  ERROR_GETTING_FIREBASE_TOKEN,
} from '../constants/user.constants'

const initialState = {
  self: null,
  updatingSelf: false,
  gettingFirebaseToken: false,
  subscribedToUsers: false,
  gettingSelf: false,
  expertDetails: null,
  gettingExpertDetails: false,
  issuingOneCreditForUser: false,
  error: false,
  errorMessage: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING_SELF:
      return {
        ...state,
        gettingSelf: true,
        error: false,
      }
    case UPDATING_SELF:
      return {
        ...state,
        updatingSelf: true,
        error: false,
      }
    case UPDATED_SELF:
      return {
        ...state,
        updatingSelf: false,
        error: false,
      }
    case ERROR_UPDATING_SELF:
      return {
        ...state,
        updatingSelf: false,
        error: true,
        errorMessage: action.payload.message,
      }
    case GETTING_FIREBASE_TOKEN:
      return {
        ...state,
        // self: action.payload,
        gettingFirebaseToken: true,
        error: false,
      }
    case GOT_FIREBASE_TOKEN:
      return {
        ...state,
        // self: action.payload,
        gettingFirebaseToken: false,
        error: false,
      }
    case ERROR_GETTING_FIREBASE_TOKEN:
      return {
        ...state,
        gettingFirebaseToken: false,
        error: true,
        errorMessage: action.payload.message,
      }
    case SUBSCRIBING_TO_USER:
      return {
        ...state,
        subscribedToUsers: false,
      }
    case SUBSCRIBED_TO_USER:
      return {
        ...state,
        subscribedToUsers: true,
      }
    case GETTING_OWN_EXPERT_DETAILS:
      return {
        ...state,
        gettingExpertDetails: true,
        error: false,
      }
    case GET_SELF_SUCCEED:
      return {
        ...state,
        self: action.payload,
        error: false,
        gettingSelf: false,
      }
    case GET_SELF_ERROR:
      return {
        ...state,
        gettingSelf: false,
        error: true,
        errorMessage: action.payload,
      }
    case GET_OWN_EXPERT_DETAILS:
      return {
        ...state,
        expertDetails: action.payload,
        gettingExpertDetails: false,
        error: false,
      }
    case GET_OWN_EXPERT_DETAILS_ERROR:
      return {
        ...state,
        gettingExpertDetails: false,
        error: true,
        errorMessage: action.payload,
      }
    case ISSUING_ONE_CREDIT_FOR_USER:
      return {
        ...state,
        issuingOneCreditForUser: true,
      }
    case ISSUED_ONE_CREDIT_FOR_USER:
      return {
        ...state,
        issuingOneCreditForUser: false,
      }
    case ISSUE_ONE_CREDIT_FOR_USER_ERROR:
      return {
        ...state,
        issuingOneCreditForUser: false,
        error: true,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}
