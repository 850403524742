import Parse from '../parse'
const GenusCareInfo = Parse.Object.extend('GenusCareInfo')

export default {
  async getGenusCareInfos(params) {
    let genusCareInfoQuery = new Parse.Query(GenusCareInfo)

    if (params.genusId) {
      genusCareInfoQuery.equalTo('genusId', params.genusId)
    }

    genusCareInfoQuery
      .include('monthId')
      .include('regions')
      .include('icon')

    const results = await genusCareInfoQuery.find()

    return results.map(genus => genus.toJSON())
  },

  async getGenusCareInfo(objectId) {
    let genusCareInfoQuery = new Parse.Query(GenusCareInfo)

    const result = await genusCareInfoQuery.get(objectId)

    return result.toJSON()
  },
}
