export const ADD_SNAPP_ALERT = 'ADD_SNAPP_ALERT'
export const REMOVE_SNAPP_ALERT = 'REMOVE_SNAPP_ALERT'

export const GET_SNAPPS_SUCCEED = 'GET_SNAPPS_SUCCEED'
export const GETTING_SNAPPS = 'GETTING_SNAPPS'
export const GET_SNAPPS_ERROR = 'GET_SNAPPS_ERROR'

export const GETTING_SNAPP = 'GETTING_SNAPP'
export const GET_SNAPP_SUCCEED = 'GET_SNAPP_SUCCEED'
export const GET_SNAPP_ERROR = 'GET_SNAPP_ERROR'

export const SET_SNAPPS_REQUEST_PARAMS = 'SET_SNAPPS_REQUEST_PARAMS'

export const LOCKING_SNAPP = 'LOCKING_SNAPP'
export const LOCKED_SNAPP = 'LOCKED_SNAPP'
export const ERROR_LOCKING_SNAPP = 'ERROR_LOCKING_SNAPP'

export const LOCK_LIST_SNAPP = 'LOCK_LIST_SNAPP'

export const UNLOCK_LIST_SNAPP = 'UNLOCK_LIST_SNAPP'
export const UPDATING_SNAPP = 'UPDATING_SNAPP'
export const UPDATED_SNAPP = 'UPDATED_SNAPP'
export const ERROR_UPDATING_SNAPP = 'ERROR_UPDATING_SNAPP'
export const ASSIGNING_PLANT_TO_SNAPP = 'ASSIGNING_PLANT_TO_SNAPP'
export const ASSIGNED_PLANT_TO_SNAPP = 'ASSIGNED_PLANT_TO_SNAPP'
export const ERROR_ASSIGNING_PLANT_TO_SNAPP = 'ERROR_ASSIGNING_PLANT_TO_SNAPP'
