export const GETTING_PLANTS = 'GETTING_PLANTS'
export const GET_PLANTS_SUCCEED = 'GET_PLANTS_SUCCEED'
export const GETTING_PLANT = 'GETTING_PLANT'
export const GET_PLANT_SUCCEED = 'GET_PLANT_SUCCEED'
export const UPDATING_PLANT = 'UPDATING_PLANT'
export const UPDATE_PLANT_SUCCEED = 'UPDATE_PLANT_SUCCEED'
export const ERROR_UPDATING_PLANT = 'ERROR_UPDATING_PLANT'
export const GETTING_PLANT_CARE_INFO = 'GETTING_PLANT_CARE_INFO'
export const GET_PLANT_CARE_INFO_SUCCEED = 'GET_PLANT_CARE_INFO_SUCCEED'
export const CREATING_PLANT = 'CREATING_PLANT'
export const CREATED_PLANT = 'CREATED_PLANT'
export const ERROR_CREATING_PLANT = 'ERROR_CREATING_PLANT'
