import {
  GET_CARE_ICONS_SUCCEED,
  GETTING_CARE_ICONS,
  GETTING_CARE_ICON,
  GET_CARE_ICON_SUCCEED,
} from '../constants/careIcon.constants'

const initialState = {
  careIcons: [],
  gettingCareIcons: false,
  careIcon: {},
  gettingCareIcon: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING_CARE_ICONS:
      return {
        ...state,
        gettingCareIcons: true,
      }
    case GET_CARE_ICONS_SUCCEED:
      return {
        ...state,
        careIcons: action.payload,
        gettingCareIcons: false,
      }
    case GETTING_CARE_ICON:
      return {
        ...state,
        gettingCareIcon: true,
      }
    case GET_CARE_ICON_SUCCEED:
      return {
        ...state,
        careIcon: action.payload,
        gettingCareIcon: false,
      }
    default:
      return state
  }
}
