import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

class ExtraPhotoCarousel extends Component {
  static propTypes = {
    photos: PropTypes.array,
    snapp: PropTypes.object,
  }

  static defaultProps = {
    snapp: {
      extraPhotos: [],
    },
  }

  state = {
    activeIndex: 0,
    animating: false,
    photos: [],
    fullscreenPhoto: null,
  }

  setPhotos() {
    const { snapp } = this.props
    const photos = snapp.extraPhotos
      ? snapp.extraPhotos
          .filter((p) => p.thumbImage)
          .map((p) => ({
            src: p.thumbImage.url,
            alt: '',
            caption: '',
          }))
      : []

    if (snapp.snappImageURI || snapp.snappImage) {
      photos.unshift({
        src: snapp.snappImageURI || snapp.snappImage.url,
        alt: '',
        caption: '',
      })
    }

    this.setState({
      ...this.state,
      photos,
    })
  }

  componentDidMount() {
    this.setPhotos()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.snapp.objectId !== this.props.snapp.objectId &&
      this.state.photos.length
    ) {
      this.setPhotos()
      this.setState({
        activeIndex: 0,
      })
    }
  }

  next = () => {
    const nextIndex =
      this.state.activeIndex >= this.state.photos.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setActiveIndex(nextIndex)
  }

  previous = () => {
    const prevIndex =
      this.state.activeIndex === 0 ? 0 : this.state.activeIndex - 1
    this.setActiveIndex(prevIndex)
  }

  goToIndex = (index) => {
    if (this.animating) return

    this.setActiveIndex(index)
  }

  setActiveIndex = (newIndex) => {
    this.setPhotos()
    this.setState({
      activeIndex: newIndex,
    })
  }

  setAnimating = (newValue) => {
    this.setState({
      animating: newValue,
    })
  }

  render() {
    const { activeIndex, photos, fullscreenPhoto } = this.state
    return (
      <>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          interval={1500}
        >
          {photos && (
            <CarouselIndicators
              items={photos}
              activeIndex={activeIndex}
              onClickHandler={this.goToIndex}
            />
          )}

          {photos.map((p, i) => {
            return (
              <CarouselItem
                onExiting={() => this.setAnimating(true)}
                onExited={() => this.setAnimating(false)}
                key={i}
              >
                <div
                  style={{ maxHeight: '400px', overflow: 'hidden' }}
                  onClick={() =>
                    this.setState({
                      fullscreenPhoto: p,
                    })
                  }
                >
                  <img
                    src={p.src}
                    className="img-fluid"
                    alt={p.alt}
                    style={{ objectFit: 'cover', width: '100%' }}
                  />
                </div>
                <CarouselCaption
                  captionText={p.caption}
                  captionHeader={p.caption}
                />
              </CarouselItem>
            )
          })}

          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
        {fullscreenPhoto && (
          <Modal
            isOpen={Boolean(fullscreenPhoto)}
            toggle={() => this.setState({ fullscreenPhoto: null })}
          >
            <ModalHeader
              toggle={() => this.setState({ fullscreenPhoto: null })}
            ></ModalHeader>
            <ModalBody>
              <img
                src={fullscreenPhoto.src}
                className="img-fluid"
                alt={fullscreenPhoto.alt}
                style={{ objectFit: 'cover', width: '100%' }}
              />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        )}
      </>
    )
  }
}

export default ExtraPhotoCarousel
