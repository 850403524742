import {
  GET_MONTHS_SUCCEED,
  GETTING_MONTHS,
  GETTING_MONTH,
  GET_MONTH_SUCCEED,
} from '../constants/month.constants'
import monthApi from '../api/modules/month.api'

export default {
  getMonths(params) {
    return async dispatch => {
      dispatch({
        type: GETTING_MONTHS,
      })
      const genusCareInfos = await monthApi.getMonths(params)
      dispatch({
        type: GET_MONTHS_SUCCEED,
        payload: genusCareInfos,
      })
      return genusCareInfos
    }
  },
  getMonth(objectId) {
    return async dispatch => {
      dispatch({ type: GETTING_MONTH })
      const genusCareInfo = await monthApi.getMonth(objectId)
      dispatch({
        type: GET_MONTH_SUCCEED,
        payload: genusCareInfo,
      })
      return genusCareInfo
    }
  },
}
