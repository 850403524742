import {
  GET_GENERA_SUCCEED,
  GET_GENUS_SUCCEED,
  GETTING_GENERA_OPTIONS,
  GET_GENERA_OPTIONS_SUCCEED,
} from '../constants/genus.constants'

const initialState = {
  genera: [],
  gettingGenera: false,
  genus: {},
  gettingGenus: false,
  gettingGenusOptions: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERA_SUCCEED:
      return {
        ...state,
        gettingPlants: true,
      }
    case GET_GENUS_SUCCEED:
      return {
        ...state,
      }
    case GETTING_GENERA_OPTIONS:
      return {
        ...state,
        gettingGenusOptions: true,
      }
    case GET_GENERA_OPTIONS_SUCCEED:
      return {
        ...state,
        gettingGenusOptions: false,
      }
    default:
      return state
  }
}
