import careInfoApi from '../api/modules/careInfo.api'
import {
  GET_CARE_INFOS_SUCCEED,
  GETTING_CARE_INFOS,
  GETTING_CARE_INFO,
  GET_CARE_INFO_SUCCEED,
  UPDATING_CARE_INFO,
  UPDATE_CARE_SUCCEED,
  CREATING_CARE_INFO,
  CREATED_CARE_INFO,
  ERROR_UPDATING_CARE_INFO,
  ERROR_CREATING_CARE_INFO,
  SET_CARE_INFOS_REQUEST_PARAMS,
} from '../constants/careInfo.constants'

export default {
  getCareInfos(params) {
    return async dispatch => {
      dispatch({
        type: SET_CARE_INFOS_REQUEST_PARAMS,
        payload: params
      })
      dispatch({
        type: GETTING_CARE_INFOS,
      })
      const careInfos = await careInfoApi.getCareInfos(params)
      dispatch({
        type: GET_CARE_INFOS_SUCCEED,
        payload: careInfos,
      })
      return careInfos
    }
  },
  getCareInfo(objectId) {
    return async dispatch => {
      dispatch({
        type: GETTING_CARE_INFO
      })
      const careInfo = await careInfoApi.getCareInfo(objectId)
      dispatch({
        type: GET_CARE_INFO_SUCCEED,
        payload: careInfo
      })
      return careInfo
    }
  },
  updateCareInfo(careInfo) {
    return async dispatch => {
      dispatch({
        type: UPDATING_CARE_INFO,
      })
      let care = {}
      try {
        care = await careInfoApi.updateCareInfo(careInfo)
      } catch (err) {
        return dispatch({
          type: ERROR_UPDATING_CARE_INFO,
          payload: err
        })
      }
      return dispatch({
        type: UPDATE_CARE_SUCCEED,
        payload: care
      })
    }
  },
  createCareInfo(careInfo) {
    return async dispatch => {
      dispatch({
        type: CREATING_CARE_INFO
      })
      let care = {}
      try {
        care = await careInfoApi.createCareInfo(careInfo)
      } catch (err) {
        return dispatch({
          type: ERROR_CREATING_CARE_INFO,
          payload: err
        })
      }
      dispatch({
        type: CREATED_CARE_INFO,
        payload: care
      })
      return care.toJSON()
    }
  },
}
