import {
  GETTING_SNAPP_COMMENTS,
  GET_SNAPP_COMMENTS_SUCCEED,
  GETTING_SNAPP_COMMENT,
  GET_SNAPP_COMMENT_SUCCEED,
  UPDATING_SNAPP_COMMENT,
  UPDATE_SNAPP_COMMENT_SUCCEED,
  SUBSCRIBING_TO_SNAPP_COMMENTS,
  SUBSCRIBED_TO_SNAPP_COMMENTS,
  CREATED_SNAPP_COMMENT_LIVE_QUERY,
  DELETED_SNAPP_COMMENT_LIVE_QUERY,
  UPDATED_SNAPP_COMMENT_LIVE_QUERY,
  GET_SNAPP_COMMENTS_ERROR,
  CREATING_SNAPP_COMMMENT,
  CREATED_SNAPP_COMMMENT,
  ERROR_CREATING_SNAPP_COMMMENT,
} from '../constants/snappComment.constants'

const initialState = {
  snappComments: [],
  gettingSnappComments: false,
  subscribedToSnappComments: false,
  creatingSnappComment: false,
  snappComment: {},
  gettingSnappComment: false,
  error: false,
  errorMessage: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING_SNAPP_COMMENTS:
      return {
        ...state,
        gettingSnappComments: true,
        snappComments: [],
        error: false,
      }
    case GET_SNAPP_COMMENTS_SUCCEED:
      return {
        ...state,
        gettingSnappComments: false,
        snappComments: action.payload,
        error: false,
      }
    case GET_SNAPP_COMMENTS_ERROR:
      return {
        ...state,
        snappComments: [],
        gettingSnappComments: false,
        error: true,
        errorMessage: action.payload,
      }
    case SUBSCRIBING_TO_SNAPP_COMMENTS:
      return {
        ...state,
        subscribedToSnappComments: false,
      }
    case SUBSCRIBED_TO_SNAPP_COMMENTS:
      return {
        ...state,
        subscribedToSnappComments: true,
      }
    case CREATED_SNAPP_COMMENT_LIVE_QUERY: {
      console.log(action.payload)
      // TODO: just pushing isn't good enough - we're assuming the dates are chronological
      state.snappComments.push(action.payload)
      return {
        ...state,
        // we have to create a copy to trigger ui updates
        snappComments: state.snappComments.slice(),
      }
    }
    case UPDATED_SNAPP_COMMENT_LIVE_QUERY: {
      console.log(action.payload)
      const i = state.snappComments.findIndex(
        sC => sC.objectId === action.payload.objectId
      )
      if (i > -1) {
        state.snappComments[i] = action.payload
      }
      return {
        ...state,
        // we have to create a copy to trigger ui updates
        snappComments: state.snappComments.slice(),
      }
    }
    case DELETED_SNAPP_COMMENT_LIVE_QUERY: {
      console.log(action.payload)
      const i = state.snappComments.findIndex(
        sC => sC.objectId === action.payload.objectId
      )
      if (i > -1) {
        state.snappComments.splice(i, 1)
      }
      return {
        ...state,
        // we have to create a copy to trigger ui updates
        snappComments: state.snappComments.slice(),
      }
    }
    case GETTING_SNAPP_COMMENT:
      return {
        ...state,
        gettingSnappComment: true,
      }
    case GET_SNAPP_COMMENT_SUCCEED:
      return {
        ...state,
        snappComment: action.payload,
        gettingSnappComment: false,
      }
    case UPDATING_SNAPP_COMMENT:
      return {
        ...state,
        updatingSnappComment: true,
      }
    case UPDATE_SNAPP_COMMENT_SUCCEED:
      return {
        ...state,
        snappComment: action.payload,
        updatingSnappComment: false,
      }
    case CREATING_SNAPP_COMMMENT:
      return {
        ...state,
        error: false,
        creatingSnappComment: true,
      }
    case CREATED_SNAPP_COMMMENT:
      return {
        ...state,
        error: false,
        creatingSnappComment: false,
      }
    case ERROR_CREATING_SNAPP_COMMMENT:
      return {
        ...state,
        error: true,
        creatingSnappComment: false,
      }
    default:
      return state
  }
}
