export const GETTING_CARE_INFOS = 'GETTING_CARE_INFOS'
export const GET_CARE_INFOS_SUCCEED = 'GET_CARE_INFOS_SUCCEED'
export const SET_CARE_INFOS_REQUEST_PARAMS = 'SET_CARE_INFOS_REQUEST_PARAMS'
export const GETTING_CARE_INFO = 'GETTING_CARE_INFO'
export const GET_CARE_INFO_SUCCEED = 'GET_CARE_INFO_SUCCEED'
export const UPDATING_CARE_INFO = 'UPDATING_CARE_INFO'
export const UPDATE_CARE_SUCCEED = 'UPDATE_CARE_SUCCEED'
export const CREATING_CARE_INFO = 'CREATING_CARE_INFO'
export const CREATED_CARE_INFO = 'CREATED_CARE_INFO'
export const ERROR_UPDATING_CARE_INFO = 'ERROR_UPDATING_CARE_INFO'
export const ERROR_CREATING_CARE_INFO = 'ERROR_CREATING_CARE_INFO'
