import * as firebase from 'firebase/app'
import 'firebase/messaging'

import firebaseConfig from './firebaseWebConfig.json'

firebase.initializeApp(firebaseConfig)

let messaging = firebase.messaging()

messaging.usePublicVapidKey(
  process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_PUSH_KEY
)

export { firebase, messaging }
