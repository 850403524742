import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import {
  reduxForm,
  Field,
  propTypes as reduxFormPropTypes,
  SubmissionError,
} from 'redux-form'
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'

import authActions from '../actions/auth.actions'
import CustomTextInput from './form/CustomTextInput'
const form = 'login'

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.login(values)
    props.history.push('/')
  } catch (err) {
    throw new SubmissionError({
      _error: err.message,
    })
  }
}

class Login extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    ...reduxFormPropTypes,
  }

  componentDidMount() {
    this.props.reset(form)
    if (this.props.location.search) {
      let searchParams = new URLSearchParams(this.props.location.search)
      if (searchParams.has('step')) {
        const state = {}
        // construct a vanilla object from the search params
        for (const key of searchParams.keys()) {
          state[key] = searchParams.get(key)
        }
      }
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      pristine,
      submitFailed,
      error,
    } = this.props

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>SmartPlant Experts Board</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <Container fluid={true} className="bg-light pb-5 h-100">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col lg={{ size: 4, offset: 4 }}>
                <Card className="mt-3">
                  <CardBody className="pb-0">
                    <CardHeader>
                      <h2>Login</h2>
                    </CardHeader>
                    <Row className="">
                      <Col>
                        <Field
                          name="email"
                          component={CustomTextInput}
                          placeholder="email"
                          icon="far fa-envelope align-self-center text-grey-light"
                        />
                        <Field
                          type="password"
                          name="password"
                          component={CustomTextInput}
                          placeholder="password"
                          icon="fas fa-lock align-self-center text-grey-light"
                        />
                        <Button
                          type="submit"
                          color={
                            invalid || submitting || pristine
                              ? 'secondary'
                              : 'primary'
                          }
                          className="btn-block mt-md-9"
                          disabled={invalid || submitting || pristine}
                        >
                          {submitting ? (
                            <i className="fas fa-asterisk fa-spin"></i>
                          ) : (
                            'LOG IN'
                          )}
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs="12"
                        className="pt-3 text-right text-primary font-weight-light"
                      >
                        <p>Forgotten your password?</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Alert
              color="danger"
              isOpen={submitFailed}
              // toggle={}
              className="fixed-bottom m-5"
            >
              {error || 'Login failed, please try again'}
            </Alert>
          </form>
        </Container>
      </>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    initialValues: auth.initialValues,
  }
}

const mapDispatchToProps = { ...authActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    onSubmit,
    validate,
    enableReinitialize: false,
  })(Login)
)
