import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
  Navbar,
  Container,
  Col,
  FormGroup,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import careInfoActions from '../actions/careInfo.actions'
import careIconActions from '../actions/careIcon.actions';
import Layout from '../containers/Layout'
import { snappTypes } from '../constants/index'
import genusApi from '../api/modules/genus.api';
import regionApi from '../api/modules/region.api';
import categoryCareInfoApi from '../api/modules/categoryCareInfo.api';

interface CareInfoGridProps {
  careInfo: any;
  careIcon: any;
  history: any;
  careInfoRequestParams: any;
}

const ITEM_LIMIT = 20
const PAGINATION_CONTROLS = {
  'FIRST': 'first',
  'PREVIOUS': 'previous',
  'NEXT': 'next',
  'LAST': 'last',
}

const mapState = (state: CareInfoGridProps) => ({
  careInfos: state.careInfo.careInfos,
  careIcons: state.careIcon.careIcons,
  count: state.careInfo.count,
  careInfoRequestParams: state.careInfo.careInfoRequestParams,
})

const mapDispatch = {
  ...careInfoActions,
  ...careIconActions
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  history: any
}

const CareInfoGrid = (props: Props) => {
  const {
    count,
    careInfos,
    careIcons,
    careInfoRequestParams,
  } = props
  const [currentPage, setCurrentPage] = useState(0)

  const handleClickPage = (e: any, index: any) => {
    e.preventDefault()
    if (typeof index === 'number') {
      setCurrentPage(index)
      return
    }
    switch (index) {
      case PAGINATION_CONTROLS.FIRST:
        setCurrentPage(0)
        break
      case PAGINATION_CONTROLS.PREVIOUS:
        setCurrentPage(currentPage - 1)
        break
      case PAGINATION_CONTROLS.NEXT:
        setCurrentPage(currentPage + 1)
        break
      case PAGINATION_CONTROLS.LAST:
        setCurrentPage(Math.ceil(count/ITEM_LIMIT) - 1)
        break
      default:
        break
    }
  }

  useEffect(() => {
    (async function func() {
      await props.getCareInfos({
        limit: ITEM_LIMIT,
        skip: currentPage * ITEM_LIMIT,
      })
      await props.getMonths()
    })()
  }, [currentPage])

  const getIconByObjectId = (objectId: string) => {
    const res = careIcons.filter((item: any) => item.objectId === objectId)
    return res
  }

  const handleSelectPlant = async (plant: any) => {
    await props.getCareInfos({
      plant
    })
  }

  const handleSelectGenera = async (genera: any) => {
    await props.getCareInfos({
      genera
    })
  }

  const handleSelectCategory = async (category: any) => {
    await props.careInfos({
      category
    })
  }

  const handleSelectRegion = async (region: any) => {
    await props.careInfos({
      region
    })
  }
  
  return (
    <Layout>
      <Container className="p-3 bg-light">
        <Navbar color="light" sticky="top" className="p-0 m-0">
          <Col>
            <FormGroup className="my-2">
              <Select
                type="select"
                name="plants"
                placeholder="Plants"
                value={careInfoRequestParams && careInfoRequestParams.plants}
                onChange={handleSelectPlant}
                options={snappTypes}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="my-2">
              <AsyncSelect
                type="switch"
                id="generaSelect"
                name="generaSelect"
                placeholder="Filter by genera"
                value={careInfoRequestParams && careInfoRequestParams.genera}
                onChange={handleSelectGenera}
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={genusApi.loadGenusOptions}
              />
            </FormGroup>
          </Col>
          {/* <Col>
            <FormGroup className="my-2">
              <AsyncSelect
                type="switch"
                id="categoriesSelect"
                name="categoriesSelect"
                placeholder="Filter by category"
                value={careInfoRequestParams && careInfoRequestParams.categories}
                onChange={handleSelectCategory}
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={}
              />
            </FormGroup>
          </Col> */}
          <Col>
            <FormGroup className="my-2">
              <AsyncSelect
                type="switch"
                id="regionsSelect"
                name="regionsSelect"
                placeholder="Filter by region"
                value={careInfoRequestParams && careInfoRequestParams.regions}
                onChange={handleSelectRegion}
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={regionApi.loadRegionOptions}
              />
            </FormGroup>
          </Col>
        </Navbar>
        <Table hover responsive>
          <thead>
            <tr>
              <th>Schedule</th>
              <th>Notification</th>
              <th>Care</th>
              <th>Plants</th>
              <th>Genera</th>
              <th>Categories</th>
              <th>Locations</th>
              <th>Regions</th>
              <th>Icon</th>
            </tr>
          </thead>
          <tbody>
            {careInfos.map((careInfo: any, index: any) => (
              <tr key={careInfo.objectId}>
                <td>
                  {careInfo.notificationSchedule && careInfo.notificationSchedule}
                </td>
                <td>
                  {careInfo.careNotification && careInfo.careNotification}
                </td>
                <td>
                  {careInfo.care && careInfo.care.join(', ')}
                </td>
                <td>
                  {careInfo.plants && careInfo.plants}
                </td>
                <td>
                  {careInfo.genera && careInfo.genera.map((item: any) => item.genusName).join(', ')}
                </td>
                <td>
                  {careInfo.categories && careInfo.categories}
                </td>
                <td>
                  {careInfo.locations && careInfo.locations.join(', ')}
                </td>
                <td>
                  {careInfo.region && careInfo.region}
                </td>
                <td>
                  {getIconByObjectId(careInfo.objectId)}
                </td>
                <td>
                  <a href={`/care-info/${careInfo.objectId}`}>View</a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <nav>
          <Pagination>
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink first onClick={e => handleClickPage(e, PAGINATION_CONTROLS.FIRST)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink previous onClick={e => handleClickPage(e, PAGINATION_CONTROLS.PREVIOUS)} />
            </PaginationItem>
            {[...Array(Math.ceil(count/ITEM_LIMIT))].map((item, index) => (
              <PaginationItem active={index === currentPage} key={index}>
                <PaginationLink onClick={e => handleClickPage(e, index)}>
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage + 1 >= Math.ceil(count/ITEM_LIMIT)}>
              <PaginationLink next onClick={e => handleClickPage(e, PAGINATION_CONTROLS.NEXT)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage + 1 >= Math.ceil(count/ITEM_LIMIT)}>
              <PaginationLink last onClick={e => handleClickPage(e, PAGINATION_CONTROLS.LAST)} />
            </PaginationItem>
          </Pagination>
        </nav>
      </Container>
    </Layout>
  )
}

export default connector(CareInfoGrid)
