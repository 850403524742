import Parse from '../parse'

const Region = Parse.Object.extend('Region')

export default {
  async getRegions() {
    let regionQuery = new Parse.Query(Region)

    const results = await regionQuery.find()

    return results.map(region => region.toJSON())
  },

  async loadRegionOptions(inputValue) {
    let regionQuery = new Parse.Query(Region)

    if (inputValue) {
      regionQuery.matches('regionName', new RegExp(inputValue, 'i'))
    }

    let results = await regionQuery.find()

    return results.map(region => ({
      value: region.id,
      label: region.get('regionName'),
    }))
  },

  async getRegion(objectId) {
    let regionQuery = new Parse.Query(Region)

    const result = await regionQuery.get(objectId)

    return result.toJSON()
  },
}
