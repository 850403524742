import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth.reducers'
import snapp from './snapp.reducers'
import snappComments from './snappComment.reducers'
import snappLedger from './snappLedger.reducers'
import plant from './plant.reducers'
import region from './region.reducers'
import genus from './genus.reducers'
import genusCareInfo from './genusCareInfo.reducers'
import month from './month.reducers'
import careIcon from './careIcon.reducers'
import user from './user.reducers'
import careInfo from './careInfo.reducers'

const rootReducer = combineReducers({
  auth,
  snapp,
  snappComments,
  snappLedger,
  region,
  genus,
  genusCareInfo,
  month,
  careIcon,
  user,
  plant,
  careInfo,
  form: formReducer,
})

export default rootReducer
