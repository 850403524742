import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class Loading extends PureComponent {
  static propTypes = {
    absolutePosition: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    absolutePosition: true,
  }

  render() {
    return (
      <div
        className={cx(
          'd-flex justify-content-center align-items-start p-5 w-100 h-100 loading-overlay',
          {
            'position-absolute': this.props.absolutePosition,
          }
        )}
      >
        <i className="fas fa-asterisk fa-spin display-3 loading-spinner"></i>
      </div>
    )
  }
}

export default Loading
