export const GETTING_SNAPP_COMMENTS = 'GETTING_SNAPP_COMMENTS'
export const GET_SNAPP_COMMENTS_SUCCEED = 'GET_SNAPP_COMMENTS_SUCCEED'
export const GET_SNAPP_COMMENTS_ERROR = 'GET_SNAPP_COMMENTS_ERROR'
export const SUBSCRIBING_TO_SNAPP_COMMENTS = 'SUBSCRIBING_TO_SNAPP_COMMENTS'
export const SUBSCRIBED_TO_SNAPP_COMMENTS = 'SUBSCRIBED_TO_SNAPP_COMMENTS'
export const CREATED_SNAPP_COMMENT_LIVE_QUERY =
  'CREATED_SNAPP_COMMENT_LIVE_QUERY'
export const UPDATED_SNAPP_COMMENT_LIVE_QUERY =
  'UPDATED_SNAPP_COMMENT_LIVE_QUERY'
export const DELETED_SNAPP_COMMENT_LIVE_QUERY =
  'DELETED_SNAPP_COMMENT_LIVE_QUERY'
export const GETTING_SNAPP_COMMENT = 'GETTING_SNAPP_COMMENT'
export const GET_SNAPP_COMMENT_SUCCEED = 'GET_SNAPP_COMMENT_SUCCEED'
export const UPDATING_SNAPP_COMMENT = 'UPDATING_SNAPP_COMMENT'
export const UPDATE_SNAPP_COMMENT_SUCCEED = 'UPDATE_SNAPP_COMMENT_SUCCEED'

export const CREATING_SNAPP_COMMMENT = 'CREATING_SNAPP_COMMMENT'
export const CREATED_SNAPP_COMMMENT = 'CREATED_SNAPP_COMMMENT'
export const ERROR_CREATING_SNAPP_COMMMENT = 'ERROR_CREATING_SNAPP_COMMMENT'
