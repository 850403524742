import snappApi from '../api/modules/snapp.api'
import {
  GET_SNAPPS_SUCCEED,
  GET_SNAPP_SUCCEED,
  LOCKING_SNAPP,
  LOCK_LIST_SNAPP,
  ERROR_LOCKING_SNAPP,
  UNLOCK_LIST_SNAPP,
  SET_SNAPPS_REQUEST_PARAMS,
  GETTING_SNAPPS,
  GET_SNAPPS_ERROR,
  UPDATING_SNAPP,
  ERROR_UPDATING_SNAPP,
  UPDATED_SNAPP,
  ASSIGNING_PLANT_TO_SNAPP,
  ERROR_ASSIGNING_PLANT_TO_SNAPP,
  ASSIGNED_PLANT_TO_SNAPP,
  ADD_SNAPP_ALERT,
  REMOVE_SNAPP_ALERT,
  GETTING_SNAPP,
  GET_SNAPP_ERROR,
  LOCKED_SNAPP,
} from '../constants/snapp.constants'
import moment from '../../node_modules/moment/moment'

export default {
  addSnappAlert(alert) {
    return dispatch => {
      dispatch({
        type: ADD_SNAPP_ALERT,
        payload: alert,
      })
    }
  },
  removeSnappAlert(index) {
    return dispatch => {
      dispatch({
        type: REMOVE_SNAPP_ALERT,
        payload: index,
      })
    }
  },
  getSnapps(params) {
    return async dispatch => {
      dispatch({
        type: SET_SNAPPS_REQUEST_PARAMS,
        payload: params,
      })
      dispatch({
        type: GETTING_SNAPPS,
      })
      let snapps = []
      try {
        snapps = await snappApi.getSnapps(params)
      } catch (err) {
        console.error(err.message)
        return dispatch({
          type: GET_SNAPPS_ERROR,
          payload: err,
        })
      }
      return dispatch({
        type: GET_SNAPPS_SUCCEED,
        payload: snapps,
      })
    }
  },
  getSnapp(objectId) {
    return async dispatch => {
      dispatch({
        type: GETTING_SNAPP,
      })
      try {
        const snapp = await snappApi.getSnapp(objectId)
        dispatch({
          type: GET_SNAPP_SUCCEED,
          payload: snapp,
        })
        return snapp
      } catch (err) {
        dispatch({
          type: GET_SNAPP_ERROR,
          payload: err,
        })
      }
    }
  },
  lockSnapp(snapp, self) {
    return async dispatch => {
      dispatch({ type: LOCKING_SNAPP })
      snapp.beingIdentifiedUntilTime = {
        __type: 'Date',
        iso: moment()
          .add(7, 'm')
          .toISOString(),
      }
      snapp.beingIdentifiedByExpert = {
        __type: 'Pointer',
        className: '_User',
        objectId: self.objectId,
      }
      try {
        const updatedSnapp = await snappApi.updateSnapp(snapp, self)
        return dispatch({
          type: LOCKED_SNAPP,
          payload: updatedSnapp,
        })
      } catch (err) {
        return dispatch({ type: ERROR_LOCKING_SNAPP, payload: err })
      }
    }
  },
  lockListSnapp(snapp, self) {
    return async dispatch => {
      dispatch({ type: LOCKING_SNAPP })
      snapp.beingIdentifiedUntilTime = {
        __type: 'Date',
        iso: moment()
          .add(7, 'm')
          .toISOString(),
      }
      snapp.beingIdentifiedByExpert = {
        __type: 'Pointer',
        className: '_User',
        objectId: self.objectId,
      }
      try {
        const updatedSnapp = await snappApi.updateSnapp(snapp, self)
        return dispatch({
          type: LOCK_LIST_SNAPP,
          payload: updatedSnapp,
        })
      } catch (err) {
        return dispatch({ type: ERROR_LOCKING_SNAPP, payload: err })
      }
    }
  },
  unlockListSnapp(objectId) {
    return async dispatch => {
      return dispatch({
        type: UNLOCK_LIST_SNAPP,
        payload: objectId,
      })
    }
  },
  updateSnapp(values) {
    return async dispatch => {
      dispatch({
        type: UPDATING_SNAPP,
      })
      let snapp = {}
      try {
        snapp = await snappApi.updateSnapp(values)
      } catch (err) {
        return dispatch({
          type: ERROR_UPDATING_SNAPP,
          payload: err,
        })
      }
      return dispatch({
        type: UPDATED_SNAPP,
        payload: snapp,
      })
    }
  },
  assigningPlantToSnapp(values) {
    return async dispatch => {
      dispatch({
        type: ASSIGNING_PLANT_TO_SNAPP,
      })
      let snapp = {}
      try {
        snapp = await snappApi.assigningPlantToSnapp(values)
      } catch (err) {
        return dispatch({
          type: ERROR_ASSIGNING_PLANT_TO_SNAPP,
          payload: err,
        })
      }
      return dispatch({
        type: ASSIGNED_PLANT_TO_SNAPP,
        payload: snapp,
      })
    }
  },
}
