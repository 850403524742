import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label } from 'reactstrap'

import AsyncSelect from 'react-select/async-creatable'

class CustomAsyncSelect extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    loadOptions: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    isLoading: false,
  }

  render() {
    const { input, meta, label, ...props } = this.props

    return (
      <FormGroup>
        {label && (
          <Label for={input.name} className="text-secondary">
            {label}
          </Label>
        )}
        <AsyncSelect cacheOptions {...input} onBlur={() => {}} {...props} />
      </FormGroup>
    )
  }
}

export default CustomAsyncSelect
