import Parse from '../parse'
const Month = Parse.Object.extend('Month')

export default {
  async getMonths() {
    let monthQuery = new Parse.Query(Month)

    const results = await monthQuery.find()

    return results.map(genus => genus.toJSON())
  },

  async getMonth(objectId) {
    let monthQuery = new Parse.Query(Month)

    const result = await monthQuery.get(objectId)

    return result.toJSON()
  },
}
